import { memo } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useCompaniesById, usePatchCompany } from "@/services";
import { SectionHeader } from "@/components";
import { Skeleton } from "@/components/ui/skeleton";
import type { CompanyUpdate } from "@/types";

export const WidgetFeatures = memo(() => {
  const company = useCompaniesById();
  const patchCompany = usePatchCompany();

  // Show loading state while company data is being fetched
  if (company.isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-64 w-full" />
      </div>
    );
  }

  // Show error state if company data fetch failed
  if (company.isError) {
    return (
      <div className="p-4 text-destructive">
        Error loading company data. Please try again later.
      </div>
    );
  }

  // Only render when company data is available
  if (!company?.data?._id) {
    return (
      <div className="p-4">
        No company data available. Please make sure you have selected a company.
      </div>
    );
  }

  const handleFeatureToggle = (feature: "chat" | "webCall", enabled: boolean) => {
    const currentFeatures = company.data?.expansion?.widgets ?? { enabled: false, chat: false, webCall: false };
    
    const updateData: CompanyUpdate = {
      expansion: {
        widgets: {
          ...currentFeatures,
          [feature]: enabled,
          // Ensure enabled is true if any feature is enabled, false if none are
          enabled: enabled || currentFeatures.webCall || currentFeatures.chat
        }
      }
    };
    patchCompany.mutate(updateData);
  };

  return (
    <div className="p-8">
      <SectionHeader title="Widget Features" />
      <Card>
        <CardContent className="p-6">
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="chat-widget">Chat Widget</Label>
                <p className="text-sm text-muted-foreground">
                  Enable or disable the chat widget functionality on your website
                </p>
              </div>
              <Switch
                id="chat-widget"
                checked={company.data?.expansion?.widgets?.chat ?? false}
                onCheckedChange={(checked) => handleFeatureToggle("chat", checked)}
                disabled={patchCompany.isPending}
              />
            </div>
            
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="web-call">Web Call Widget</Label>
                <p className="text-sm text-muted-foreground">
                  Enable or disable the web call widget functionality on your website
                </p>
              </div>
              <Switch
                id="web-call"
                checked={company.data?.expansion?.widgets?.webCall ?? false}
                onCheckedChange={(checked) => handleFeatureToggle("webCall", checked)}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
});

WidgetFeatures.displayName = "WidgetFeatures"; 