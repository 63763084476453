export const getAMPMTimeString = (value: number): string => {
  const hoursMilitary = Math.floor(value / 60);
  const hours = hoursMilitary > 12 ? hoursMilitary - 12 : hoursMilitary;
  const mins = value % 60;
  return `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins} ${value >= 720 ? "PM" : "AM"}`;
};

export const getMillitaryTimeString = (value: number): string => {
  const hours = Math.floor(value / 60);
  const mins = value % 60;
  return `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`;
};

export const getMinutes = (value: string): number => {
  const hours = parseInt(value.split(":")[0]);
  const minutes = parseInt(value.split(":")[1]);
  return hours * 60 + minutes;
};
