import { AgentPersonality, NavigationItem } from "@types";
import {
  Building2,
  MessagesSquare,
  Users2,
  Wrench,
  Building,
  Puzzle,
  Users,
  ChartLine,
  NotebookPen,
  LandPlot,
  DatabaseZap,
} from "lucide-react";

export type SidebarSection = {
  title: string;
  items: NavigationItem[];
};

export type Config = {
  navigation: {
    sidebar: SidebarSection[];
  };
  personality: {
    options: AgentPersonality[];
  };
  industry: {
    options: string[];
  };
  language: {
    options: Record<string, string>[];
  };
  backgroundSound: {
    options: { id: string; name: string }[];
  };
};

const config: Config = {
  navigation: {
    sidebar: [
      {
        title: "Admin",
        items: [
          {
            href: "/company-selection",
            icon: Building2,
            roles: new Set(["super", "owner"]),
            title: "Company Selection",
          },
          {
            href: "/playbooks",
            icon: NotebookPen,
            title: "Playbooks",
            roles: new Set(["super"]),
          },
          {
            href: "/campaigns",
            icon: LandPlot,
            title: "Campaigns",
            roles: new Set(["super"]),
          },
          {
            href: "/manage-settings",
            icon: DatabaseZap,
            title: "Manage Settings",
            roles: new Set(["super"]),
          },
        ],
      },
      {
        title: "Platform",
        items: [
          {
            href: "/stats",
            icon: ChartLine,
            title: "Stats",
            roles: new Set(["super", "admin", "owner"]),
          },
          {
            href: "/conversations",
            icon: MessagesSquare,
            title: "Conversations",
          },
          {
            href: "/agents",
            icon: Users2,
            title: "Agents",
            roles: new Set(["super", "admin", "owner"]),
          },
          {
            href: "/services",
            icon: Wrench,
            title: "Services",
            roles: new Set(["super", "admin", "owner"]),
          },
          {
            href: "/company",
            icon: Building,
            title: "Company",
            roles: new Set(["super", "admin", "owner"]),
          },
        ],
      },
      {
        title: "Settings",
        items: [
          {
            href: "/integrations",
            icon: Puzzle,
            title: "Integrations",
            roles: new Set(["super"]),
          },
          {
            href: "/team",
            icon: Users,
            title: "Team",
            roles: new Set(["admin", "owner", "super"]),
          },
        ],
      },
    ],
  },
  industry: {
    options: ["HVAC", "Pest control", "Plumbing", "Roofing", "Other"],
  },
  personality: {
    options: [
      "Good Neighbor",
      "Seasoned Pro",
      "Problem Solver",
      "Straightforward Expert",
      "Trustworthy Advisor",
      "Long-time Office Lady",
      "Guy at the Hardware Store",
      "Patient Tour Guide",
      "On-call Technician",
    ],
  },
  backgroundSound: {
    options: [
      { id: "off", name: "Off" },
      { id: "office", name: "Office" },
    ],
  },
  language: {
    options: [
      { key: "en", label: "English" },
      { key: "es", label: "Spanish" },
      { key: "fr", label: "French" },
      { key: "de", label: "German" },
      { key: "it", label: "Italian" },
      { key: "pt", label: "Portuguese" },
      { key: "zh", label: "Chinese" },
      { key: "ja", label: "Japanese" },
      { key: "ko", label: "Korean" },
      { key: "ru", label: "Russian" },
      { key: "tr", label: "Turkish" },
      { key: "vi", label: "Vietnamese" },
    ],
  },
};

export default config;
