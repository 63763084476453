import { UnexpectedVector } from "@assets";
import { useNavigateBack } from "@hooks";
import { Button } from "@components";
import { FC, memo } from "react";

export type ErrorInfoProps = {
  title: string;
  subtitle: string;
  description: string;
};

export const ErrorInfo: FC<ErrorInfoProps> = memo(
  ({ description, subtitle, title }) => {
    const navigateBack = useNavigateBack();
    return (
      <div className="flex gap-4 px-8 pb-8">
        <UnexpectedVector height={120} />
        <div>
          <h2 className="text-4xl font-semibold">{title}</h2>
          <h3 className="text-xl font-semibold">{subtitle}</h3>
          <p>{description}</p>
          <div className="flex mt-2">
            <Button onClick={navigateBack}>Go back!</Button>
          </div>
        </div>
      </div>
    );
  },
);
