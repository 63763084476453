import { AuthenticatedLayout } from "@layouts";
import { Building } from "lucide-react";
import { memo } from "react";
import { Outlet } from "react-router";
import { Role } from "@types";
import { useCompaniesById } from "@/services";
import { Skeleton } from "@/components/ui/skeleton";

const baseTabs = [
  {
    path: "/company",
    label: "Info",
  },
  {
    path: "/company/hours",
    label: "Hours",
  },
  {
    path: "/company/service-areas",
    label: "Service Areas",
  },
  {
    path: "/company/priorities",
    label: "Priorities",
  },
  {
    path: "/company/transferring-numbers",
    label: "Transferring Numbers",
  },
  {
    path: "/company/text-messaging",
    label: "Text Messaging",
  },
  {
    path: "/company/expansion",
    label: "Expansion",
    roles: new Set<Role>(["super"]),
  },
  {
    path: "/company/chat-widget",
    label: "Chat Widget",
    roles: new Set<Role>(["super", "owner", "admin"]),
  },
];

export const Company = memo(() => {
  const { data: companyData, isLoading, isError } = useCompaniesById();

  const isChatWidgetEnabled =
    companyData && companyData.expansion?.widgets?.chat === true;

  const filteredTabs = isChatWidgetEnabled
    ? baseTabs
    : baseTabs.filter((tab) => tab.path !== "/company/chat-widget");

  if (isLoading) {
    return (
      <AuthenticatedLayout
        description="Loading company information..."
        icon={<Building size={32} />}
        title="Company"
        tabs={[]}
      >
        <div className="p-8">
          <Skeleton className="mb-4 h-8 w-1/4" />
          <Skeleton className="h-64 w-full" />
        </div>
      </AuthenticatedLayout>
    );
  }

  if (isError) {
    return (
      <AuthenticatedLayout
        description="Could not load company information."
        icon={<Building size={32} />}
        title="Company"
        tabs={[]}
      >
        <div className="p-4 text-destructive-foreground bg-destructive rounded-md">
          Error loading company data. Please try again later.
        </div>
      </AuthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout
      description="Manage your company information"
      icon={<Building size={32} />}
      title="Company"
      tabs={filteredTabs}
    >
      <Outlet />
    </AuthenticatedLayout>
  );
});

Company.displayName = "Company";
