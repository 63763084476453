import { Component, ErrorInfo, ReactNode } from "react";
import { toast } from "react-toastify";
import { Button } from "./ui/button";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class IndexedDBErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  // Helper function to check if error is IndexedDB related
  private static isIndexedDBError(error: Error): boolean {
    return (
      error.name === "InvalidStateError" || // Common IndexedDB error
      error.message.includes("IndexedDB") ||
      error.message.includes("Indexed Database") ||
      error.message.toLowerCase().includes("indexeddb")
    );
  }

  static getDerivedStateFromError(error: Error) {
    if (this.isIndexedDBError(error)) {
      return { hasError: true };
    }
    throw error;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (IndexedDBErrorBoundary.isIndexedDBError(error)) {
      console.error("IndexedDB Error:", {
        error,
        errorInfo,
        name: error.name,
        message: error.message,
      });

      toast.error(
        "Database connection issue detected. Refreshing the page may resolve this.",
        {
          toastId: "indexeddb-error", // Prevent duplicate toasts
          autoClose: 5000,
        },
      );
    }
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center p-4 space-y-4">
          <div className="text-lg font-semibold text-foreground">
            Database Connection Issue
          </div>
          <p className="text-sm text-muted-foreground text-center max-w-md">
            We encountered an issue with the browser's database connection. This
            is usually temporary and can be fixed by refreshing the page.
          </p>
          <Button onClick={this.handleRefresh} variant="primary" size="default">
            Refresh Page
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}
