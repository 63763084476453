import { forwardRef } from "react";
import type { ReactNode } from "react";
import type { SelectProps } from "@radix-ui/react-select";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "../ui/form";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "../ui/select";

const formItemVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "flex flex-col gap-2",
      left: "flex gap-8 items-center",
    },
    hideLabel: {
      true: "flex flex-col gap-0",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
  compoundVariants: [
    {
      labelPlacement: "left",
      hideLabel: true,
      className: "flex gap-0 items-center",
    },
  ],
});

const formLabelVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "",
      left: "w-[104px] flex-shrink-0",
    },
    hideLabel: {
      true: "hidden",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

export interface FormSelectInputProps
  extends VariantProps<typeof formItemVariants> {
  className?: string;
  helperText?: ReactNode;
  label?: string;
  placeholder?: string;
  rightElement?: ReactNode;
  onBlur?: () => void;
  "data-testid"?: string;
}

export const FormSelectInput = forwardRef<
  HTMLSelectElement,
  SelectProps & FormSelectInputProps
>(
  (
    {
      children,
      className,
      helperText,
      label,
      placeholder,
      rightElement,
      labelPlacement,
      hideLabel,
      "data-testid": testId,
      ...selectProps
    },
    ref,
  ) => {
    const id = `${label || "select"}-input`;

    return (
      <FormItem
        className={cn(
          formItemVariants({ labelPlacement, hideLabel }),
          className,
        )}
        data-testid={testId}
      >
        <FormLabel
          htmlFor={id}
          className={formLabelVariants({ labelPlacement, hideLabel })}
        >
          {label}
        </FormLabel>
        <div
          className={cn(labelPlacement === "left" ? "flex-1" : "", "w-full")}
        >
          <div className="flex items-center gap-2">
            <Select {...selectProps}>
              <FormControl>
                <SelectTrigger
                  id={id}
                  className="w-full"
                  data-testid={testId ? `select-${testId}` : undefined}
                >
                  <SelectValue ref={ref} placeholder={placeholder} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>{children}</SelectContent>
            </Select>
            {rightElement}
          </div>
          {helperText && <FormDescription>{helperText}</FormDescription>}
          <FormMessage />
        </div>
      </FormItem>
    );
  },
);
