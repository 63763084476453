import { useCompaniesById, usePatchCompany } from "@services";
import { memo, useCallback } from "react";
import { toast } from "react-toastify";
import {
  CompanyInfoForm,
  CompanyInfoFormValues,
} from "./components/CompanyInfoForm";

export const CompanyInfo = memo(() => {
  const company = useCompaniesById();
  const patchCompany = usePatchCompany();

  const submitHandler = useCallback(
    (values: CompanyInfoFormValues) => {
      const data = {
        ...values,
      };
      // TODO: this is not necessary because of the patch endpoint
      if (!values.fallbackPhone) {
        delete data.fallbackPhone;
      }
      patchCompany.mutate(data, {
        onSuccess: () => {
          toast.success("Info updated successfully");
        },
        onError: () => {
          toast.error("Failed to update info");
        },
      });
    },
    [patchCompany],
  );

  return (
    <div className="flex flex-col grow px-8 pb-8 overflow-auto h-full">
      {company.data && (
        <CompanyInfoForm
          name={company.data.name}
          onSubmit={submitHandler}
          pending={patchCompany.isPending}
          phone={company.data.phone}
          fallbackPhone={company.data.fallbackPhone}
          sipUri={company.data.sipUri}
          timeZone={company.data.timeZone}
          webhookUrl={company.data.webhookUrl ?? ""}
        />
      )}
    </div>
  );
});

export default CompanyInfo;
