import { SectionHeader, Button } from "@components";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { SelectedPriorities } from "./components/SelectedPriorities";
import {
  useAvailableStatuses,
  useCompaniesById,
  usePatchCompany,
} from "@services";
import { formatPascalCase } from "@utils/string";
import { toast } from "react-toastify";
import { SkeletonPriorities } from "./components/SkeletonPriorities";
import { Switch } from "@/components/ui/switch";
export type AvailableStatus = [string, string];

const formatCustomerCategories = (
  categories: string[],
  avaiableStatuses: AvailableStatus[],
) => {
  return categories.map((category) => {
    const status = avaiableStatuses?.find((status) => status[0] === category);
    return {
      name: category,
      description: status ? status[1] : "",
    };
  });
};

export type PriorityOption = {
  name: string;
  description: string;
};

export const CompanyPriorities = memo(() => {
  const company = useCompaniesById();
  const statuses = useAvailableStatuses();
  const patchCompany = usePatchCompany();
  const [companyPriorities, setCompanyPriorities] = useState<PriorityOption[]>(
    [],
  );

  const avaliableStatuses: PriorityOption[] = useMemo(() => {
    if (statuses.data && !statuses.isPending) {
      return statuses.data.map((status: AvailableStatus) => ({
        name: status[0],
        description: status[1],
      }));
    } else {
      return [];
    }
  }, [statuses]);

  const unselectedPriorities: PriorityOption[] = useMemo(
    () =>
      avaliableStatuses.filter(
        (availableStatus) =>
          !companyPriorities.some(
            (priority) => priority.name === availableStatus.name,
          ),
      ),
    [companyPriorities, avaliableStatuses],
  );

  const onTogglePriority = useCallback(
    (priorityName: string) => {
      setCompanyPriorities((priorities) => {
        if (priorities?.some((priority) => priority.name === priorityName)) {
          return priorities.filter(
            (priority) => priority.name !== priorityName,
          );
        } else {
          const newPriority = avaliableStatuses.find(
            (availableStatus) => availableStatus.name === priorityName,
          );
          return newPriority ? [...priorities, newPriority] : priorities;
        }
      });
    },
    [avaliableStatuses],
  );

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id && over?.id && active.id !== over.id) {
      setCompanyPriorities((items) => {
        const activeId = active.id;
        const overId = over.id;
        const oldIndex = items.findIndex((item) => item.name === activeId);
        const newIndex = items.findIndex((item) => item.name === overId);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  const onSubmit = useCallback(() => {
    const customerCategories = companyPriorities.map((p) => p.name);
    patchCompany.mutate(
      { customerCategories },
      {
        onError: () => {
          toast.error("Failed to update company priorities");
        },
      },
    );
  }, [companyPriorities, patchCompany]);

  useEffect(() => {
    if (company.data) {
      setCompanyPriorities(
        formatCustomerCategories(
          company.data?.customerCategories,
          statuses.data,
        ),
      );
    }
  }, [company.data, statuses.data]);

  return (
    <div className="flex flex-col w-full h-full overflow-auto p-8">
      <SectionHeader
        title="Company Priorities"
        subTitle="Manage The Priorities Of Your Company From Highest To Lowest Priority"
      >
        <Button size="sm" onClick={onSubmit} type="submit" variant="primary">
          Save Priorities
        </Button>
      </SectionHeader>
      {company.isPending || statuses.isPending ? (
        <SkeletonPriorities />
      ) : (
        <div className="flex flex-col gap-2 w-full">
          <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
          >
            <SortableContext
              items={companyPriorities.map((priority) => ({
                id: priority.name,
                ...priority,
              }))}
              strategy={verticalListSortingStrategy}
            >
              <SelectedPriorities
                selectedPriorities={companyPriorities}
                onTogglePriority={onTogglePriority}
              />
            </SortableContext>
          </DndContext>
          <hr className="h-px w-full bg-gray-200 border-0 my-3" />
          <ul className="flex flex-col gap-2">
            {unselectedPriorities?.map((priority) => (
              <li key={priority?.name}>
                <div className="flex flex-row items-center justify-between py-2 px-4 bg-default-100 shadow-md rounded-md">
                  <div>
                    <h4 className="text-lg capitalize">
                      {formatPascalCase(priority?.name)}
                    </h4>
                    <p className="text-sm text-default-500">
                      {priority?.description}
                    </p>
                  </div>
                  <Switch
                    onCheckedChange={() => {
                      onTogglePriority(priority?.name);
                    }}
                    value={priority?.name}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

export default CompanyPriorities;
