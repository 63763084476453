import { SectionHeader, Button } from "@components";
import { Skeleton } from "@/components/ui/skeleton";
import { memo } from "react";
import { PhoneItem } from "./PhoneItem";

const SKELETON_COUNT = 20;

const mockPhoneValue = {
  name: "Test",
  message: "100000",
  condition: "always",
  phoneNumber: "+11111122222",
  trigger: "trigger",
};

export const TransferringNumbersSkeleton = memo(() => (
  <div className="flex flex-col gap-6 p-8 overflow-auto h-full">
    <SectionHeader title="Transferring Numbers">
      <Skeleton className="h-9 w-16">
        <Button size="sm">Save</Button>
      </Skeleton>
    </SectionHeader>
    <div className="flex flex-row flex-wrap gap-4">
      {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
        <Skeleton key={index} className="rounded-lg">
          <PhoneItem onEdit={() => null} value={mockPhoneValue} />
        </Skeleton>
      ))}
    </div>
  </div>
));
