import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface AvailableStatusesRequest {
  accessToken?: string;
  companyId?: string;
}

export const fetchAvailableStatuses = async ({
  accessToken,
  companyId,
}: AvailableStatusesRequest) => {
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/${companyId}/available-statuses`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch available statuses", error);
    throw error;
  }
};

export const useAvailableStatuses = () => {
  const { accessToken, companyId } = useSession();
  return useQuery({
    queryKey: ["useAvailableStatuses", companyId],
    queryFn: () => fetchAvailableStatuses({ accessToken, companyId }),
  });
};
