import { forwardRef, useCallback, useMemo, useState } from "react";
import { Check, ChevronDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export type Option = {
  label: string;
  value: string;
};

export type MultiSelectProps = {
  options: Option[];
  placeholder?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
};

export const MultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      placeholder = "Select items",
      value = [],
      onChange,
    }: MultiSelectProps,
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    const toggleOption = useCallback(
      (option: Option) => {
        const newValue = value.includes(option.value)
          ? value.filter((v) => v !== option.value)
          : [...value, option.value];
        onChange?.(newValue);
      },
      [value, onChange],
    );

    const memoizedData = useMemo(() => {
      const optionsMap = Object.fromEntries(
        options.map((option) => [option.value, option.label]),
      );

      const renderedOptions = options.map((option: Option) => (
        <DropdownMenuItem
          key={option.value}
          onSelect={(e) => {
            e.preventDefault();
            toggleOption(option);
          }}
        >
          <Check
            className={cn(
              "mr-2 h-4 w-4",
              value.includes(option.value) ? "opacity-100" : "opacity-0",
            )}
          />
          {option.label}
        </DropdownMenuItem>
      ));

      return { optionsMap, renderedOptions };
    }, [options, value, toggleOption]);

    const renderSelectedValues = useCallback(() => {
      if (value.length === 0) {
        return <li className="text-muted-foreground">{placeholder}</li>;
      }
      if (value.length === 1) {
        return (
          <li key={value[0]}>
            {memoizedData?.optionsMap[value[0]] ?? value[0]}
          </li>
        );
      }
      return value.map((val, index) => {
        const isLast = value.length - 1 === index;
        return (
          <li key={val}>
            {memoizedData?.optionsMap[val] ?? val}
            {isLast ? "" : ","}
          </li>
        );
      });
    }, [value, placeholder, memoizedData?.optionsMap]);

    return (
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            ref={ref}
            variant="outlined"
            role="combobox"
            aria-expanded={open}
            aria-controls="multi-select-dropdown"
            className="min-w-[200px] h-auto justify-between rounded-md"
          >
            <ul
              className="flex flex-wrap gap-1 items-center"
              aria-label="Selected items"
            >
              {renderSelectedValues()}
            </ul>
            <ChevronDown className="ml-2 h-4 w-4 opacity-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="overflow-y-auto max-h-[15rem] w-[var(--radix-dropdown-menu-trigger-width)]"
          align="start"
          sideOffset={4}
        >
          {memoizedData.renderedOptions}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  },
);

MultiSelect.displayName = "MultiSelect";
