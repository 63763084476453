import { useCompaniesById } from "@services";
import { memo } from "react";

export const InactiveCompanyBanner = memo(() => {
  const { data: company } = useCompaniesById();
  
  if (!company || company.voiceAiProvider !== "None") {
    return null;
  }
  
  return (
    <div className="w-full bg-danger text-white p-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 mr-2" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
            />
          </svg>
          <h3 className="text-lg font-bold">Warning: This company is inactive</h3>
        </div>
        <p>
          Please contact <a href="mailto:support@gosameday.com" className="underline font-medium">support@gosameday.com</a> to get it activated again.
        </p>
      </div>
    </div>
  );
});

export default InactiveCompanyBanner;
