import { forwardRef, useState, useEffect, type ComponentProps } from "react";
import { Input } from "@components";
import type { InputProps } from "../ui/input";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { cn } from "@/lib/utils";

const formatCurrency = (value: string | number | undefined): string => {
  if (!value) return "";
  if (typeof value === "number") {
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const numericValue = parseFloat(value.replace(/[$,]/g, ""));
  if (isNaN(numericValue)) {
    return value;
  } else {
    return numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

// Function to strip formatting but keep numeric input
const stripFormatting = (value: string | number | undefined): string => {
  if (!value) return "";
  return String(value).replace(/[$,]/g, "");
};

export type FormCurrencyInputProps = {
  label?: string;
  helperText?: React.ReactNode;
  value?: string | number;
  onChange?: (value: string) => void;
  disabled?: boolean;
  hideFormMessage?: boolean;
};

export const FormCurrencyInput = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<"input">, "value" | "onChange"> &
    InputProps &
    FormCurrencyInputProps
>(
  (
    {
      label,
      helperText,
      className,
      value,
      onChange,
      disabled,
      hideFormMessage = false,
      ...inputProps
    },
    ref,
  ) => {
    // Track if the input is being edited
    const [isEditing, setIsEditing] = useState(false);
    // Store the raw input value during editing
    const [internalValue, setInternalValue] = useState("");

    // Update internal state when value prop changes, but only if not editing
    useEffect(() => {
      if (!isEditing) {
        // When not editing, display the formatted value
        const formattedValue = value ? `${stripFormatting(value)}` : "";
        setInternalValue(formattedValue);
      }
    }, [value, isEditing]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      // Only allow numbers and decimal point
      const sanitizedValue = inputValue.replace(/[^\d.]/g, "");

      // Update internal state with raw input
      setInternalValue(sanitizedValue);

      // Pass raw numeric value to parent
      onChange?.(sanitizedValue);
    };

    const handleFocus = () => {
      setIsEditing(true);
      // When focusing, show the raw value without formatting
      const rawValue = stripFormatting(value);
      setInternalValue(rawValue);
    };

    const handleBlur = () => {
      setIsEditing(false);

      // Only format if there's a value
      if (internalValue) {
        const numericValue = parseFloat(internalValue);
        if (!isNaN(numericValue)) {
          // Update parent with the raw value
          onChange?.(String(numericValue));
        }
      }
    };

    // Determine what to display
    const displayValue = isEditing
      ? internalValue
      : internalValue
        ? `$${formatCurrency(internalValue)}`
        : "";

    return (
      <FormItem className={className}>
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <Input
            ref={ref}
            {...inputProps}
            className={cn(
              disabled && "bg-muted cursor-not-allowed text-muted-foreground",
            )}
            type="text"
            value={displayValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </FormControl>
        <FormDescription>{helperText}</FormDescription>
        {!hideFormMessage && <FormMessage />}
      </FormItem>
    );
  },
);

FormCurrencyInput.displayName = "FormCurrencyInput";
