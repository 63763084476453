import { ReactNode, forwardRef } from "react";
import { ChipsInput } from "../ChipsInput";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField,
} from "../ui/form";

const formItemVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "flex flex-col gap-2",
      left: "flex gap-8 items-center",
    },
    hideLabel: {
      true: "!gap-0",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

const formLabelVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "",
      left: "w-[104px] flex-shrink-0",
    },
    hideLabel: {
      true: "hidden",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

interface FormChipsTextInputProps
  extends VariantProps<typeof formItemVariants> {
  label?: ReactNode;
  helperText?: ReactNode;
  className?: string;
  hideLabel?: boolean;
  hideFormMessage?: boolean;
  placeholder?: string;
  values: string[];
  onChange: (values: string[]) => void;
  validate?: (value: string) => boolean;
  variant?: "default" | "secondary" | "destructive" | "success" | "outline";
  type?: string;
}

export const FormChipsTextInput = forwardRef<
  HTMLInputElement,
  FormChipsTextInputProps
>(
  (
    {
      label,
      helperText,
      className,
      labelPlacement,
      hideLabel,
      hideFormMessage = false,
      placeholder,
      values,
      onChange,
      validate,
      variant = "default",
      type,
      ...props
    },
    ref,
  ) => {
    const formField = useFormField();
    const error = formField?.error;
    const isError = !!error;

    return (
      <FormItem
        className={cn(
          formItemVariants({ labelPlacement, hideLabel }),
          className,
        )}
      >
        {label && (
          <FormLabel
            className={formLabelVariants({ labelPlacement, hideLabel })}
          >
            {label}
          </FormLabel>
        )}
        <div className={cn(labelPlacement === "left" ? "flex-1" : "")}>
          <FormControl>
            <ChipsInput
              ref={ref}
              placeholder={placeholder}
              values={values}
              onChange={onChange}
              validate={validate}
              variant={variant}
              type={type}
              isError={isError}
              {...props}
            />
          </FormControl>
          {helperText && <FormDescription>{helperText}</FormDescription>}
          {!hideFormMessage && <FormMessage />}
        </div>
      </FormItem>
    );
  },
);

FormChipsTextInput.displayName = "FormChipsTextInput";
