import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { usePostBuyTextNumber, usePostGenerateTextNumber } from "@services";
import { Button } from "@components";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";

type TextNumberGenerateFormProps = {
  hasExplicitContent: boolean;
};

export const TextNumberGenerateForm = ({
  hasExplicitContent,
}: TextNumberGenerateFormProps) => {
  const [checked, setChecked] = useState(hasExplicitContent);
  const [areaCode, setAreaCode] = useState("");
  const [generatedNumber, setGeneratedNumber] = useState("");
  const generateTextNumber = usePostGenerateTextNumber();
  const buyTextNumber = usePostBuyTextNumber();

  const handleCheckboxChange = useCallback(
    () => setChecked((prev) => !prev),
    [],
  );

  const handleGenerateNumber = useCallback(() => {
    generateTextNumber.mutate(
      { areaCode: areaCode },
      {
        onSuccess: (data) => {
          setGeneratedNumber(data.phoneNumber);
          toast.success("Text number is generated successfully.");
        },
        onError: () => {
          toast.error("Failed to generate text phone number.");
        },
      },
    );
  }, [areaCode, generateTextNumber]);

  const handleSaveNumber = useCallback(() => {
    buyTextNumber.mutate({ phoneNumber: generatedNumber });
  }, [buyTextNumber, generatedNumber]);

  return (
    <div className="p-6">
      <div className="flex items-center bg-green-100 p-4 rounded-md shadow-sm gap-4">
        <Checkbox
          className="data-[state=checked]:bg-success data-[state=checked]:text-success-foreground"
          checked={checked}
          onCheckedChange={handleCheckboxChange}
        />
        <Icon icon="ic:round-info" className="text-black text-3xl" />
        <p>
          I acknowledge that I have obtained explicit consent from callers to my
          business to also receive automated texts for marketing and sales
          purposes in compliance with{" "}
          <a
            href="https://www.fcc.gov/general/telecommunications-consumer-protection-act"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Telephone Consumer Protections Act (TCPA)
          </a>
        </p>
      </div>

      <div className="mt-6">
        <h6 className="text-xl">Generate Texting Number</h6>
        <p className="text-gray-500 mb-4">
          Generate a number based off your business’ area code
        </p>
        <div className="flex items-center space-x-4">
          <Input
            placeholder="Area code"
            value={areaCode}
            disabled={generatedNumber ? true : false}
            onChange={(e) => setAreaCode(e.target.value)}
            className="w-28"
          />
          <Button
            isLoading={generateTextNumber.isPending}
            variant="primary"
            onClick={handleGenerateNumber}
            disabled={
              generateTextNumber.isPending || (generatedNumber ? true : false)
            }
            className="text-black border-black capitalize hover:bg-gray-100"
          >
            Generate
          </Button>
          {generatedNumber && <p className="font-medium">{generatedNumber}</p>}
          {generatedNumber && (
            <Button
              isLoading={buyTextNumber.isPending}
              disabled={buyTextNumber.isPending}
              variant="primary"
              onClick={handleSaveNumber}
              className="capitalize"
            >
              Save Number
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
