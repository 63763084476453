import { useState, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChatWidget } from "./ChatWidget";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, Copy, Check } from "lucide-react";
import { Button } from "@components";
import { ChipsInput } from "@components";
import { usePatchCompany } from "@/services";
import { CompanyUpdate } from "@types";

interface ChatWidgetConfig {
  companyId: string;
  userBubble: string;
  aiBubble: string;
  backGround: string;
  button: string;
  header: string;
  nameHeader: string;
  agentName: string;
  speechBubbleMessage: string;
  initialMessage: string;
  allowedOrigins: string[];
}

interface ChatWidgetEditorProps {
  initialConfig: Partial<ChatWidgetConfig>;
  secret: string;
  htmlSnippet: string;
  companyId: string;
}

export const ChatWidgetEditor = ({
  initialConfig,
  secret,
  htmlSnippet,
  companyId,
}: ChatWidgetEditorProps) => {
  const [configError, setConfigError] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const patchCompany = usePatchCompany();

  const [config, setConfig] = useState<ChatWidgetConfig>({
    companyId: companyId,
    userBubble: initialConfig.userBubble || "#E25B45",
    aiBubble: initialConfig.aiBubble || "#F8F9FA",
    backGround: initialConfig.backGround || "#FFFFFF",
    button: initialConfig.button || "#E25B45",
    header: initialConfig.header || "#E25B45",
    nameHeader: initialConfig.nameHeader || "#FFFFFF",
    agentName: initialConfig.agentName || "Maggie",
    speechBubbleMessage:
      initialConfig.speechBubbleMessage ||
      "I'm here to help if you have any questions or need assistance.",
    initialMessage:
      initialConfig.initialMessage ||
      "I'm Maggie, your AI assistant. I'm here to help you today",
    allowedOrigins: initialConfig.allowedOrigins || [],
  });

  // Validate configuration when companyId changes
  useEffect(() => {
    if (!companyId || companyId.trim() === "") {
      setConfigError("Company ID is required. Please select a valid company.");
    } else {
      setConfigError(null);
      // Update the config with the new companyId
      setConfig((prev) => ({ ...prev, companyId }));
    }
  }, [companyId]);

  const handleChange = (
    key: keyof ChatWidgetConfig,
    value: string | string[],
  ) => {
    const updatedConfig = { ...config, [key]: value };
    setConfig(updatedConfig);
  };

  const getInstallationCode = () => {
    // Validate both companyId and htmlSnippet before returning
    if (!companyId || companyId.trim() === "") return "";
    if (!htmlSnippet || typeof htmlSnippet !== "string") return "";

    return htmlSnippet;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(getInstallationCode());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSaveConfig = () => {
    const updateData: CompanyUpdate = {
      chatWidgetConfig: {
        chatWidgetAllowedOrigins: config.allowedOrigins,
        customizableInput: {
          userBubble: config.userBubble,
          aiBubble: config.aiBubble,
          backGround: config.backGround,
          button: config.button,
          header: config.header,
          nameHeader: config.nameHeader,
          agentName: config.agentName,
          initialMessage: config.initialMessage,
          speechBubbleMessage: config.speechBubbleMessage,
        },
      },
    };

    patchCompany.mutate(updateData);
  };

  return (
    <Card>
      <CardContent className="p-0 pt-6">
        {configError && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{configError}</AlertDescription>
          </Alert>
        )}

        <Tabs defaultValue="config">
          <TabsList className="mb-4 mx-6">
            <TabsTrigger value="config">Config</TabsTrigger>
            <TabsTrigger value="installation">Installation</TabsTrigger>
          </TabsList>

          <TabsContent
            value="config"
            className="space-y-4 max-h-[800px] overflow-y-auto px-8 pb-6"
          >
            <div className="flex justify-between items-center pb-4 sticky top-0 bg-background">
              <h3 className="text-lg font-medium">Configuration</h3>
              <Button variant="primary" onClick={handleSaveConfig}>
                Save Config
              </Button>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="userBubble">User Bubble Color</Label>
                <div className="flex gap-2">
                  <Input
                    id="userBubble"
                    type="color"
                    value={config.userBubble}
                    onChange={(e) => handleChange("userBubble", e.target.value)}
                    className="w-12 h-10 p-1"
                  />
                  <Input
                    type="text"
                    value={config.userBubble}
                    onChange={(e) => handleChange("userBubble", e.target.value)}
                    className="flex-1"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="aiBubble">AI Bubble Color</Label>
                <div className="flex gap-2">
                  <Input
                    id="aiBubble"
                    type="color"
                    value={config.aiBubble}
                    onChange={(e) => handleChange("aiBubble", e.target.value)}
                    className="w-12 h-10 p-1"
                  />
                  <Input
                    type="text"
                    value={config.aiBubble}
                    onChange={(e) => handleChange("aiBubble", e.target.value)}
                    className="flex-1"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="backGround">Background Color</Label>
                <div className="flex gap-2">
                  <Input
                    id="backGround"
                    type="color"
                    value={config.backGround}
                    onChange={(e) => handleChange("backGround", e.target.value)}
                    className="w-12 h-10 p-1"
                  />
                  <Input
                    type="text"
                    value={config.backGround}
                    onChange={(e) => handleChange("backGround", e.target.value)}
                    className="flex-1"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="button">Widget Color</Label>
                <div className="flex gap-2">
                  <Input
                    id="button"
                    type="color"
                    value={config.button}
                    onChange={(e) => handleChange("button", e.target.value)}
                    className="w-12 h-10 p-1"
                  />
                  <Input
                    type="text"
                    value={config.button}
                    onChange={(e) => handleChange("button", e.target.value)}
                    className="flex-1"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="header">Header Color</Label>
                <div className="flex gap-2">
                  <Input
                    id="header"
                    type="color"
                    value={config.header}
                    onChange={(e) => handleChange("header", e.target.value)}
                    className="w-12 h-10 p-1"
                  />
                  <Input
                    type="text"
                    value={config.header}
                    onChange={(e) => handleChange("header", e.target.value)}
                    className="flex-1"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="nameHeader">Header Text Color</Label>
                <div className="flex gap-2">
                  <Input
                    id="nameHeader"
                    type="color"
                    value={config.nameHeader}
                    onChange={(e) => handleChange("nameHeader", e.target.value)}
                    className="w-12 h-10 p-1"
                  />
                  <Input
                    type="text"
                    value={config.nameHeader}
                    onChange={(e) => handleChange("nameHeader", e.target.value)}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="agentName">Agent Name</Label>
                <Input
                  id="agentName"
                  type="text"
                  value={config.agentName}
                  onChange={(e) => handleChange("agentName", e.target.value)}
                  placeholder="Enter agent name"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="speechBubbleMessage">
                  Speech Bubble Message
                </Label>
                <Input
                  id="speechBubbleMessage"
                  type="text"
                  value={config.speechBubbleMessage}
                  onChange={(e) =>
                    handleChange("speechBubbleMessage", e.target.value)
                  }
                  maxLength={200}
                  placeholder="Enter speech bubble message"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="initialMessage">Initial Message</Label>
                <Input
                  id="initialMessage"
                  type="text"
                  value={config.initialMessage}
                  onChange={(e) =>
                    handleChange("initialMessage", e.target.value)
                  }
                  placeholder="Enter initial message"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="websiteUrl">Website URLs</Label>
                <ChipsInput
                  values={config.allowedOrigins}
                  onChange={(urls) => {
                    handleChange("allowedOrigins", urls);
                  }}
                  placeholder="Enter website URLs (press Enter or comma to add)"
                />
              </div>
            </div>
          </TabsContent>

          <TabsContent
            value="installation"
            className="space-y-4 max-h-[800px] overflow-y-auto p-6 pr-8"
          >
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Installation Instructions</h3>
              <p>
                To add the chat widget to your website, copy the code snippet
                below and paste it into your website's HTML, just before the
                closing <code>&lt;/body&gt;</code> tag.
              </p>

              <div className="relative">
                <pre className="bg-muted p-4 rounded-md overflow-x-auto text-sm">
                  <code>{getInstallationCode()}</code>
                </pre>
                <Button
                  size="sm"
                  variant="outline"
                  className="absolute top-2 right-2"
                  onClick={copyToClipboard}
                >
                  {copied ? (
                    <>
                      <Check className="h-4 w-4 mr-2" />
                      Copied!
                    </>
                  ) : (
                    <>
                      <Copy className="h-4 w-4 mr-2" />
                      Copy
                    </>
                  )}
                </Button>
              </div>

              <div className="mt-4">
                <h4 className="text-md font-medium mb-2">Additional Notes:</h4>
                <ul className="list-disc pl-5 space-y-1">
                  <li>
                    The widget will appear as a chat button in the bottom right
                    corner of your website.
                  </li>
                  <li>
                    You can customize the appearance of the widget in the Config
                    tab.
                  </li>
                  <li>
                    The widget will use the configuration you've set up here.
                  </li>
                  <li>
                    For any issues with the widget, please contact support.
                  </li>
                </ul>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
      <ChatWidget config={config} secret={secret} companyId={companyId} />
    </Card>
  );
};
