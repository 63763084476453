import { Fragment, memo, useCallback } from "react";
import { Moon, Settings, LogOut, User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Switch } from "@/components/ui/switch";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useTheme } from "@/components/ui/theme-provider";
import { SidebarTrigger } from "../ui/sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSession } from "@store";
import { useCompaniesById } from "@/services";

function formatBreadCrumb(segment: string): string {
  return segment
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const TopHeader = memo(() => {
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useSession();
  const company = useCompaniesById();
  const pathSegments = location.pathname.split("/");
  pathSegments.shift(); // removes empty first item

  const clickHandler = useCallback(
    () => setTheme(theme === "dark" ? "light" : "dark"),
    [theme, setTheme],
  );

  const logoutHandler = useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);

  const settingsHandler = useCallback(() => {
    navigate("/user-settings");
  }, [navigate]);

  return (
    <header className="h-14 w-full bg-sidebar-background border-b border-input">
      <div className="flex h-full items-center justify-between px-6">
        <div className="flex items-center gap-6">
          <SidebarTrigger className="text-foreground" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem className="text-foreground">
                <BreadcrumbLink asChild className="hover:text-foreground/60">
                  <Link to="/">{company?.data?.name}</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {pathSegments.map((segment, idx) => {
                const link = `/${pathSegments.slice(0, idx + 1).join("/")}`; // Gets the necessary /path to the breadcrumb
                return (
                  <Fragment key={segment}>
                    <BreadcrumbSeparator className="text-foreground" />
                    <BreadcrumbItem className="text-foreground">
                      {idx < pathSegments.length - 1 ? (
                        <BreadcrumbLink
                          asChild
                          className="hover:text-foreground/60"
                        >
                          <Link to={link}>{formatBreadCrumb(segment)}</Link>
                        </BreadcrumbLink>
                      ) : (
                        <BreadcrumbPage className="text-foreground">
                          {formatBreadCrumb(segment)}
                        </BreadcrumbPage>
                      )}
                    </BreadcrumbItem>
                  </Fragment>
                );
              })}
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="flex h-9 w-9 items-center justify-center rounded-full bg-muted hover:bg-muted/80 transition-colors">
              <User className="h-5 w-5 text-foreground" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[200px]">
            <DropdownMenuItem
              className="flex items-center justify-between"
              onSelect={(e) => e.preventDefault()}
            >
              <div className="flex items-center">
                <Moon className="mr-2 h-4 w-4" />
                <span>Dark Mode</span>
              </div>
              <Switch
                checked={theme === "dark"}
                onCheckedChange={clickHandler}
                className="ml-2"
              />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={settingsHandler}>
              <Settings className="mr-2 h-4 w-4" />
              <span>Personal Settings</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="text-red-600" onClick={logoutHandler}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
});
