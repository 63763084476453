import { memo, useCallback, useMemo, useState } from "react";
import { PhoneItem } from "./components/PhoneItem";
import { TransferPhone } from "@types";
import {
  FormValues,
  TransferringNumberModal,
} from "./components/TransferringNumberModal";
import { useCompaniesById, usePatchCompany } from "@services";
import { Button, SectionHeader } from "@components";
import { TransferringNumbersSkeleton } from "./components/TransferringNumbersSkeleton";

export const TransferringNumbers = memo(() => {
  const company = useCompaniesById();
  const transferPhones = useMemo(
    () => company.data?.transferPhones || [],
    [company.data?.transferPhones],
  );
  const [editPhone, setEditPhone] = useState<TransferPhone | undefined>();
  const addHandler = useCallback(
    () =>
      setEditPhone({
        name: "",
        trigger: "",
        phoneNumber: "",
        condition: "",
        message: "",
      }),
    [],
  );
  const modalCloseHandler = useCallback(() => {
    setEditPhone(undefined);
  }, []);
  const patchCompany = usePatchCompany();
  const submitHandler = useCallback(
    (values: FormValues) => {
      const phone = transferPhones.find(
        (phone: TransferPhone) =>
          phone.name === values.name ||
          phone.phoneNumber === values.phoneNumber,
      ) as TransferPhone;
      if (phone) {
        patchCompany.mutate(
          {
            transferPhones: transferPhones.map((item) =>
              item.name === phone.name || item.phoneNumber === phone.phoneNumber
                ? values
                : item,
            ),
          },
          { onSuccess: () => setEditPhone(undefined) },
        );
      } else {
        patchCompany.mutate(
          {
            transferPhones: [...transferPhones, values],
          },
          { onSuccess: () => setEditPhone(undefined) },
        );
      }
    },
    [patchCompany, transferPhones],
  );
  const phoneEditHandler = useCallback(
    (value: TransferPhone) => setEditPhone(value),
    [],
  );
  const phoneDeleteHandler = useCallback(
    (phone: TransferPhone) => {
      patchCompany.mutate(
        {
          transferPhones: transferPhones.filter(
            (item) => item.phoneNumber !== phone.phoneNumber,
          ),
        },
        { onSuccess: () => setEditPhone(undefined) },
      );
    },
    [patchCompany, transferPhones],
  );
  return company.isLoading ? (
    <TransferringNumbersSkeleton />
  ) : (
    <section className="flex flex-col flex-grow gap-8 p-8 h-full overflow-auto">
      <SectionHeader title="Transferring Numbers">
        <Button
          className="self-end"
          variant="primary"
          onClick={addHandler}
          size="sm"
        >
          Add Transfer Number
        </Button>
      </SectionHeader>
      <div className="flex flex-row flex-wrap gap-4">
        {transferPhones.map((phone) => (
          <PhoneItem
            key={phone.phoneNumber}
            onEdit={phoneEditHandler}
            value={phone}
          />
        ))}
      </div>
      <TransferringNumberModal
        onClose={modalCloseHandler}
        onDelete={phoneDeleteHandler}
        onSubmit={submitHandler}
        open={!!editPhone}
        saving={patchCompany.isPending}
        values={
          editPhone || {
            name: "",
            trigger: "",
            phoneNumber: "",
            condition: "",
            message: "",
          }
        }
      />
    </section>
  );
});
