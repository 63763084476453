import { memo, useCallback, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "@/components/ui/button";
import { Form, FormField } from "@/components/ui/form";
import { FormTextInput } from "@/components";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const passwordSchema = z.object({
  password: z.string().min(8, "Password must be at least 8 characters long"),
});

type SetPasswordInputs = {
  password: string;
};

type SetPasswordProps = {
  onSubmitHandler: (data: { password: string }) => void;
};

export const SetPasswordForm = memo(({ onSubmitHandler }: SetPasswordProps) => {
  const form = useForm<SetPasswordInputs>({
    resolver: zodResolver(passwordSchema),
    mode: "onBlur",
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = form

  const isLoading = false;

  const onSubmit: SubmitHandler<SetPasswordInputs> = useCallback(
    (data) => {
      onSubmitHandler(data);
    },
    [onSubmitHandler],
  );

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleVisibility = useCallback(
    () => setIsPasswordVisible(!isPasswordVisible),
    [isPasswordVisible],
  );

  return (
    <Form {...form}>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormTextInput
              label="Password"
              placeholder="Enter your new password"
              type={isPasswordVisible ? "text" : "password"}
              rightIcon={
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 hover:bg-transparent"
                  onClick={toggleVisibility}
                >
                  {isPasswordVisible ? (
                    <Icon
                      className="pointer-events-none text-2xl text-default-400"
                      icon="solar:eye-closed-linear"
                    />
                  ) : (
                    <Icon
                      className="pointer-events-none text-2xl text-default-400"
                      icon="solar:eye-bold"
                    />
                  )}
                </Button>
              }
              {...field}
            />
          )}
        />
        <Button
          size="sm"
          isLoading={isLoading}
          isDisabled={!isValid}
          type="submit"
          variant="primary"
        >
          Submit
        </Button>
      </form>
    </Form>
  );
});
