import { memo, useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useCompaniesById, usePatchCompany } from "@/services";
import { SectionHeader, Collapsible, CollapsibleContent, CollapsibleTrigger } from "@components";
import { Skeleton } from "@/components/ui/skeleton";
import { ChevronDown } from "lucide-react";
import type { CompanyUpdate } from "@/types";
import React from "react";

export const CompanyExpansion = memo(() => {
  const company = useCompaniesById();
  const patchCompany = usePatchCompany();
  const [isOpen, setIsOpen] = useState(false);
  const [localWidgetState, setLocalWidgetState] = useState({
    enabled: false,
    chat: false,
    webCall: false
  });

  // Initialize local state when company data is loaded
  React.useEffect(() => {
    if (company.data?.expansion?.widgets) {
      setLocalWidgetState({
        enabled: company.data.expansion.widgets.enabled ?? false,
        chat: company.data.expansion.widgets.chat ?? false,
        webCall: company.data.expansion.widgets.webCall ?? false
      });
    }
  }, [company.data?.expansion?.widgets]);

  // Show loading state while company data is being fetched
  if (company.isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-64 w-full" />
      </div>
    );
  }

  // Show error state if company data fetch failed
  if (company.isError) {
    return (
      <div className="p-4 text-destructive">
        Error loading company data. Please try again later.
      </div>
    );
  }

  // Only render when company data is available
  if (!company?.data?._id) {
    return (
      <div className="p-4">
        No company data available. Please make sure you have selected a company.
      </div>
    );
  }

  const handleFeatureToggle = (
    subFeature: "enabled" | "chat" | "webCall",
    enabled: boolean
  ) => {
    // Update local state immediately for optimistic UI
    const newLocalState = {
      ...localWidgetState
    };

    // Handle main widget toggle
    if (subFeature === "enabled") {
      newLocalState.enabled = enabled;
      if (enabled) {
        // When enabling main toggle, enable chat by default
        newLocalState.chat = true;
      } else {
        // When disabling main toggle, disable all sub-features
        newLocalState.chat = false;
        newLocalState.webCall = false;
      }
    } else {
      // Handle sub-feature toggles
      newLocalState[subFeature] = enabled;
      
      // If both sub-features are disabled, disable the main toggle
      if (!newLocalState.chat && !newLocalState.webCall) {
        newLocalState.enabled = false;
      }
      // If any sub-feature is enabled, ensure main toggle is on
      else if (newLocalState.chat || newLocalState.webCall) {
        newLocalState.enabled = true;
      }
    }

    setLocalWidgetState(newLocalState);
    
    const updateData: CompanyUpdate = {
      expansion: {
        widgets: newLocalState
      }
    };

    patchCompany.mutate(updateData);
  };

  const isUpdating = patchCompany.isPending;

  return (
    <div className="p-8">
      <SectionHeader title="Company Expansion" />
      <Card>
        <CardContent className="p-6">
          <div className="space-y-6">
            {/* Widget Feature Section */}
            <Collapsible open={isOpen} onOpenChange={setIsOpen}>
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <div className="flex items-center gap-2">
                    <Label htmlFor="widgets">Widgets</Label>
                    <CollapsibleTrigger>
                      <ChevronDown className="h-4 w-4" />
                    </CollapsibleTrigger>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    Enable or disable widget functionality for this company
                  </p>
                </div>
                <Switch
                  id="widgets"
                  checked={localWidgetState.enabled}
                  disabled={isUpdating}
                  onCheckedChange={(checked) => handleFeatureToggle("enabled", checked)}
                />
              </div>

              <CollapsibleContent className="mt-4 space-y-4 pl-6">
                <div className="flex items-center justify-between">
                  <div className="space-y-0.5">
                    <Label htmlFor="chat-widget">Chat Widget</Label>
                    <p className="text-sm text-muted-foreground">
                      Enable or disable chat widget functionality
                    </p>
                  </div>
                  <Switch
                    id="chat-widget"
                    disabled={!localWidgetState.enabled || isUpdating}
                    checked={localWidgetState.chat}
                    onCheckedChange={(checked) => handleFeatureToggle("chat", checked)}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="space-y-0.5">
                    <Label htmlFor="web-call">Web Call Widget</Label>
                    <p className="text-sm text-muted-foreground">
                      Enable or disable web call widget functionality
                    </p>
                  </div>
                  <Switch
                    id="web-call"
                    disabled={!localWidgetState.enabled || isUpdating}
                    checked={localWidgetState.webCall}
                    onCheckedChange={(checked) => handleFeatureToggle("webCall", checked)}
                  />
                </div>
              </CollapsibleContent>
            </Collapsible>

            {/* Add more expansion sections here */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
});

CompanyExpansion.displayName = "CompanyExpansion";