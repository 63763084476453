import { useSession } from "@store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

type PostBuyTextNumberParams = {
  phoneNumber: string;
};

const postBuyTextNumber = async (
  params: PostBuyTextNumberParams,
  accessToken?: string,
  companyId?: string,
) => {
  try {
    const response = await axios.post(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/${companyId}/sendo-phone-number/buy`,
      { ...params },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error buying text phone number", error);
    throw error;
  }
};

export const usePostBuyTextNumber = () => {
  const queryClient = useQueryClient();
  const { accessToken, companyId } = useSession();
  return useMutation({
    mutationFn: (params: PostBuyTextNumberParams) =>
      postBuyTextNumber(params, accessToken, companyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["useCompaniesById", companyId],
      });
    },
  });
};
