import { ComponentPropsWithoutRef, ElementType, ReactNode, memo } from "react";
import { type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { buttonVariants } from "./button";


export interface LinkButtonProps<C extends ElementType>
  extends VariantProps<typeof buttonVariants> {
    as?: C;
    children?: ReactNode;
    className?: string;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
}

const LinkButton =
  memo(<C extends ElementType="a">(
    {
      as,
      children,
      className,
      leftIcon,
      rightIcon,
      size,
      variant,
      ...props
    }: LinkButtonProps<C> & Omit<ComponentPropsWithoutRef<C>, keyof LinkButtonProps<C>>
  ) => {
    const Component = as || "a";
    return (
      <Component
        {...props}
        className={cn(buttonVariants({ variant, size, className }))}
      >
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </Component>
    );
  })

LinkButton.displayName = "LinkButton";

export { LinkButton }
