import { useCompaniesById } from "@services";
import { memo } from "react";
import { TextNumberGenerateForm } from "./components/TextNumberGenerateForm";
import { SmsForm } from "./components/SmsForm";
import { BrandForm } from "./components/BrandForm";
import { WaitForBrandApprove } from "./components/WaitForBrandApprove";

export const TextMessaging = memo(() => {
  const company = useCompaniesById();

  const smsData = company.data?.sms;
  const isBrandVerified = smsData?.brand?.status === "VERIFIED";
  const isCampaignActive = smsData?.campaign?.status === "ACTIVE";
  const hasBrandId = smsData?.brand?.id;

  return (
    <div className="flex flex-col grow h-full px-2 overflow-auto">
      {company.data &&
        (hasBrandId && isBrandVerified && isCampaignActive ? (
          smsData.phone ? (
            <SmsForm config={smsData} />
          ) : (
            <TextNumberGenerateForm
              hasExplicitContent={smsData.hasConsent ?? false}
            />
          )
        ) : hasBrandId ? (
          <WaitForBrandApprove />
        ) : (
          <BrandForm />
        ))}
    </div>
  );
});
