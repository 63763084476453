import { store } from "@store";
import { QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, useTheme } from "@/components/ui/theme-provider";
import { StrictMode } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { queryClient } from "./queryClient";
import { router } from "./router";
import "./index.css";
import "react-toastify/dist/ReactToastify.min.css";
import { IndexedDBErrorBoundary } from "./common/components/IndexedDBErrorBoundary";

const App = () => {
  const { theme } = useTheme();
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer hideProgressBar theme={theme} autoClose={1000} />
    </>
  );
};

const Providers = () => (
  <StrictMode>
    <ThemeProvider defaultTheme="light">
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <IndexedDBErrorBoundary>
            <App />
          </IndexedDBErrorBoundary>
        </ReduxProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>
);

export default Providers;
