import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import { AuthenticatedRequest } from "@types";
import axios from "axios";

export type CompaniesByIdRequest = AuthenticatedRequest & {
  companyId?: string;
};

const fetchBrandStatus = async ({
  companyId,
  accessToken,
}: CompaniesByIdRequest) => {
  if (!companyId) {
    return [];
  }
  try {
    const brandResponse = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/${companyId}/brand/status`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const campaignResponse = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/${companyId}/campaign/status`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return brandResponse.data === "VERIFIED" && campaignResponse.data === "ACTIVE";
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch companies");
  }
};

export const useGetBrandStatus = () => {
  const { accessToken, companyId } = useSession();
  const query = useQuery({
    queryKey: ["useGetBrandStatus", companyId],
    enabled: !!companyId,
    queryFn: () => fetchBrandStatus({ accessToken, companyId }),
  });

  return query;
};
