import { memo } from "react";
import { SortableItem } from "./SortableItem";
import { Icon } from "@iconify/react/dist/iconify.js";
import { PriorityOption } from "../CompanyPriorities";
import { formatPascalCase } from "@utils/string";
import { Switch } from "@/components/ui/switch";

type SelectedPrioritiesProps = {
  selectedPriorities: PriorityOption[];
  onTogglePriority: (priorityName: string) => void;
};

export const SelectedPriorities = memo(
  ({ selectedPriorities, onTogglePriority }: SelectedPrioritiesProps) => {
    return (
      <ul className="flex flex-col gap-2">
        {selectedPriorities.map((priority) => (
          <SortableItem key={priority?.name} id={priority?.name}>
            {({ noDragProps }) => (
              <div className="flex flex-row items-center justify-between py-2 px-4 bg-default-100 shadow-md rounded-md">
                <div className="flex flex-row items-center gap-2">
                  <div className="mr-1">
                    <Icon icon="ci:drag-vertical" />
                  </div>
                  <div>
                    <h4 className="text-lg capitalize">
                      {formatPascalCase(priority?.name)}
                    </h4>
                    <p className="text-sm text-default-500">
                      {priority?.description}
                    </p>
                  </div>
                </div>
                <div {...noDragProps}>
                  <Switch
                    disabled={priority?.name === "callBack"}
                    onCheckedChange={() => {
                      onTogglePriority(priority?.name);
                    }}
                    checked
                    value={priority?.name}
                  />
                </div>
              </div>
            )}
          </SortableItem>
        ))}
      </ul>
    );
  },
);
