import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ChangeEvent, FocusEvent, forwardRef, memo, useState } from "react";

type CurrencyInputProps = {
  className?: string;
  label: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<Element>) => void;
  value: string | number | undefined;
  isInvalid?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  id?: string;
};

const formatCurrency = (value: string | number | undefined): string => {
  if (!value) return "";

  // Handle number type directly
  if (typeof value === "number") {
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // More robust parsing for string values - handles various currency formats
  const numericValue = parseFloat(value.replace(/[^0-9.-]/g, ""));
  if (isNaN(numericValue)) {
    return value;
  } else {
    return numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const CurrencyInput = memo(
  forwardRef<HTMLInputElement, CurrencyInputProps>(
    (
      {
        className,
        value,
        onChange,
        onBlur,
        isDisabled,
        isInvalid,
        name,
        label,
        placeholder,
        errorMessage,
        id,
      },
      ref,
    ) => {
      const [internalValue, setInternalValue] = useState(formatCurrency(value));
      // Generate a unique ID if not provided
      const inputId = id || `currency-input-${name}`;

      const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setInternalValue(inputValue);
        onChange(e);
      };

      const handleInputBlur = (e: FocusEvent<Element>) => {
        const formattedValue = formatCurrency(internalValue);
        setInternalValue(formattedValue);
        onBlur && onBlur(e);
      };

      return (
        <div className={cn("flex flex-col gap-1.5", className)}>
          {label && (
            <label htmlFor={inputId} className="text-sm font-medium">
              {label}
            </label>
          )}
          <Input
            ref={ref}
            id={inputId}
            type="text"
            name={name}
            disabled={isDisabled}
            leftIcon={
              <span
                className={`text-sm ${isInvalid ? "text-destructive" : ""}`}
              >
                $
              </span>
            }
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={internalValue}
            placeholder={placeholder}
          />
          {isInvalid && errorMessage && (
            <p className="text-sm text-destructive">{errorMessage}</p>
          )}
        </div>
      );
    },
  ),
);
