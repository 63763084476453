import { useSortable } from "@dnd-kit/sortable";
import { memo, ReactNode } from "react";
import { CSS } from "@dnd-kit/utilities";
import type { DraggableAttributes } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

export type DragHandleProps = {
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
};

// Props to be applied to elements that should NOT trigger drag
export type NoDragProps = {
  onPointerDown: (e: React.PointerEvent) => void;
  onClick: (e: React.MouseEvent) => void;
};

type SortableItemProps = {
  id: string;
  children:
    | ReactNode
    | ((props: {
        dragHandleProps: DragHandleProps;
        noDragProps: NoDragProps;
      }) => ReactNode);
};

export const SortableItem = memo(({ id, children }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  const dragHandleProps: DragHandleProps = {
    attributes,
    listeners,
  };

  // These props should be applied to elements that should NOT trigger drag
  const noDragProps: NoDragProps = {
    onPointerDown: (e) => {
      e.stopPropagation();
    },
    onClick: (e) => {
      e.stopPropagation();
    },
  };

  return (
    <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {typeof children === "function"
        ? children({ dragHandleProps, noDragProps })
        : children}
    </li>
  );
});
