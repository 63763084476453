import { ReactNode, forwardRef, memo } from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Ripple } from "./ripple";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        primary: "bg-primary text-primary-foreground hover:bg-primary/70",
        default: "bg-default hover:bg-default/70",
        success:
          "bg-success-200 text-primary-foreground hover:bg-success-200/70",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground hover:border-black",
        outlined:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground hover:border-black",
        "outlined-destructive":
          "border border-destructive text-destructive bg-background hover:bg-danger-50",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        text: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        black: "bg-default-900 text-white hover:bg-default-800",
      },
      size: {
        default: "h-10 rounded-sm px-4 py-2",
        md: "h-10 rounded-sm px-4 py-2",
        sm: "h-9 rounded-sm px-3",
        lg: "h-11 rounded-sm px-8",
        icon: "h-10 rounded-sm w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        asChild = false,
        children,
        className,
        disabled,
        isDisabled,
        isLoading,
        leftIcon,
        rightIcon,
        size,
        variant,
        ...props
      },
      ref,
    ) => {
      const Comp = asChild ? Slot : "button";

      return (
        <Comp
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          disabled={isDisabled || disabled}
          {...props}
        >
          <Ripple />
          {isLoading && <Icon icon="svg-spinners:ring-resize" />}
          {leftIcon && leftIcon}
          {children}
          {rightIcon && rightIcon}
        </Comp>
      );
    },
  ),
);

Button.displayName = "Button";

export { Button, buttonVariants };
