import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useSession } from "@store";
import * as Sentry from "@sentry/react";
import { useEffect, useRef } from "react";

type GetStatsRequest = {
  accessToken: string;
  accountId?: string;
  days?: number;
};

type StatsResponse = {
  totalConversations: number;
  totalConversationDuration: number;
  totalConversationsPerStatus: Record<string, number>;
  totalHangups: number;
  dailyConversations: {
    date: string;
    totalConversations: number;
    totalHangups: number;
    totalConversationsPerStatus: Record<string, number>;
  }[];
  companyStats: Record<string, StatsResponse>;
};

export const getStats = async ({
  accessToken,
  accountId,
  days = 7,
}: GetStatsRequest): Promise<StatsResponse> => {
  if (!accessToken) {
    throw new Error("No access token provided");
  }

  if (!accountId) {
    throw new Error("No account ID provided");
  }

  const url = `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/accounts/${accountId}/stats/?days=${days}`;

  try {
    const response = await axios.get<StatsResponse>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Request failed:", {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
      });
    }
    throw error;
  }
};

export const useGetStats = (days: number = 7) => {
  const { accessToken, user } = useSession();
  const hasSentSentryLog = useRef(false);

  useEffect(() => {
    if (!user?.accountId && !hasSentSentryLog.current) {
      Sentry.captureMessage(
        "User without accountId attempted to use useGetStats",
        {
          level: "warning",
          tags: {
            source: "useGetStats",
            userId: user?.id || "unknown",
            email: user?.email || "unknown",
          },
          user: {
            id: user?.id,
            email: user?.email,
          },
          // Set a custom fingerprint to group all missing accountId errors together
          fingerprint: ["missing-accountId-error"],
        },
      );
      hasSentSentryLog.current = true;
    }
  }, [user?.accountId, user?.id, user?.email]);

  return useQuery({
    queryKey: ["useGetStats", user?.accountId, days],
    enabled: !!accessToken && !!user?.accountId,
    queryFn: () => getStats({ accessToken, accountId: user?.accountId, days }),
  });
};
