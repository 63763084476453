import { useSession } from "@store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CompanyUpdate } from "@types";
import axios from "axios";
import { toast } from "react-toastify";

interface PatchCompanyRequest {
  accessToken?: string;
  companyId?: string;
  data: CompanyUpdate;
}

const patchCompany = async ({
  accessToken,
  companyId,
  data,
}: PatchCompanyRequest) => {
  try {
    const response = await axios.patch(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/${companyId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to patch company", error);
    throw error;
  }
};

export const usePatchCompany = () => {
  const queryClient = useQueryClient();
  const { accessToken, companyId } = useSession();
  return useMutation({
    mutationFn: (data: CompanyUpdate) =>
      patchCompany({ accessToken, companyId, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["useCompaniesById", companyId],
      });
      queryClient.invalidateQueries({
        queryKey: ["useGetTagsInfo"],
      });
      queryClient.invalidateQueries({
        queryKey: ["useGetJobTypeInfo"],
      });
      queryClient.invalidateQueries({
        queryKey: ["useGetMembershipTypeInfo"],
      });
      toast.success("Company updated successfully!");
    },
  });
};
