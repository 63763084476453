import { memo, ReactNode } from "react";
import { useCompaniesById } from "@services";
import { PhoneNumberDisplay } from "@/components";
import { PageTabs } from "@/components/PageTabs";
import { TabItem } from "@types";

type PageHeaderProps = {
  actions?: ReactNode;
  description?: string;
  icon?: ReactNode;
  showPhoneNumber?: boolean;
  title?: string;
  tabs?: TabItem[];
};

export const PageHeader = memo(
  ({ actions, showPhoneNumber, tabs }: PageHeaderProps) => {
    const { data, isPending } = useCompaniesById();
    const phoneNumber = isPending ? "" : data?.phone;

    const shouldRenderDiv = showPhoneNumber || (tabs && tabs.length > 0) || actions;

    return (
      shouldRenderDiv ? (
        <div className={`bg-muted-background flex pt-8 px-8 items-center justify-between`}>
          {showPhoneNumber && (
            <PhoneNumberDisplay phoneNumber={phoneNumber || ""} />
          )}
          {tabs && tabs.length > 0 && <PageTabs tabs={tabs} />}
          <div className="w-full flex justify-end">{actions}</div>
        </div>
      ) : null
    );
  },
);
