import { Button, FormTextInput, FormTextArea } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { TransferPhone } from "@types";
import { FC, memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../common/components/ui/dialog";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../common/components/ui/radio-group";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../../common/components/ui/form";

export type TransferringNumberModalProps = {
  onClose: VoidFunction;
  onDelete: (values: FormValues) => void;
  onSubmit: (values: FormValues) => void;
  open: boolean;
  saving: boolean;
  values: TransferPhone;
};

export type FormValues = TransferPhone;

const schema = z.object({
  condition: z.string().min(1, "Condition is required"),
  message: z.string().min(1, "Message is required"),
  name: z.string().min(1, "Label is required"),
  phoneNumber: z.string().refine((value) => /^\+\d{9,15}$/.test(value), {
    message:
      "Fallback Phone number must be in the format + followed by 9 to 15 digits",
  }),
  trigger: z.string().min(1, "Trigger is required"),
});

export const TransferringNumberModal: FC<TransferringNumberModalProps> = memo(
  ({ onClose, onDelete, onSubmit, open, saving, values }) => {
    const form = useForm<FormValues>({
      defaultValues: values,
      resolver: zodResolver(schema),
      values,
    });

    const deleteClickHandler = useCallback(
      () => onDelete(values),
      [onDelete, values],
    );

    return (
      <Dialog
        open={open}
        onOpenChange={(isOpen: boolean) => !isOpen && onClose()}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {values.name ? `Edit ${values.name}` : "New Transferring Number"}
            </DialogTitle>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormTextInput label="Label" {...field} helperText="" />
                )}
              />

              <FormField
                control={form.control}
                name="trigger"
                render={({ field }) => (
                  <FormTextArea label="Trigger" {...field} />
                )}
              />

              <FormField
                control={form.control}
                name="condition"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Condition</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        value={field.value}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="always" />
                          </FormControl>
                          <FormLabel className="font-normal cursor-pointer">
                            Always
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="withinBusinessHours" />
                          </FormControl>
                          <FormLabel className="font-normal cursor-pointer">
                            Within Business Hours
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="outsideBusinessHours" />
                          </FormControl>
                          <FormLabel className="font-normal cursor-pointer">
                            Outside Business Hours
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormTextInput
                    label="Message"
                    helperText="Message Before Transfer"
                    {...field}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormTextInput label="Phone Number" {...field} />
                )}
              />

              <DialogFooter className="mt-6">
                <Button variant="outline" onClick={onClose} type="button">
                  Cancel
                </Button>
                {values.name && (
                  <Button
                    variant="destructive"
                    onClick={deleteClickHandler}
                    type="button"
                  >
                    Delete
                  </Button>
                )}
                <Button variant="primary" type="submit" disabled={saving}>
                  {saving ? "Saving..." : "Save"}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    );
  },
);
