import { memo } from "react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { cn } from "@/lib/utils";

type UserCardProps = {
  avatarUrl?: string;
  description?: string;
  classNames?: { 
    avatar?: string,
    base?: string,
    name?: string,
    description?: string
  };
  name?: string;
}

export const UserCard = memo(({
  avatarUrl,
  classNames,
  name,
  description,
}:UserCardProps) => {
  return (
    <div className={cn("relative z-10 flex items-center w-full h-full gap-4", classNames?.base)}>
      {/* Text Content */}
      <div>
        <h2 className={cn("text-sm font-semibold", classNames?.name)}>{name}</h2>
        <p className={cn("text-sm text-gray-200", classNames?.description)}>{description}</p>
      </div>
      {/* Avatar */}
      <Avatar className={cn("w-10 h-10 border-2 border-white", classNames?.avatar)}>
        <AvatarImage src={avatarUrl} alt={name} />
        <AvatarFallback>
          {name
            ?.split(" ")
            .map((n: string) => n[0])
            .join("")}
        </AvatarFallback>
      </Avatar>
    </div>
  )
})

UserCard.displayName = "UserCard"