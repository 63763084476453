type SamedayLogo = {
  className?: string;
  size?: number;
  height?: number;
  width?: number;
}
export const SamedayLogoHorizontal = ({
  className,
  size,
  height = 32,
  width
}: SamedayLogo) => {
  return (
    <svg
      height={size || height}
      width={size || width}
      className={className}
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 166.0925 36.1661"
      fill="currentColor"
    >
      <g id="Components">
        <g id="_452c3858-7109-40a9-8f88-9422377bbbc7_4">
          <polygon points="16.9793 0 11.2882 3.2857 11.2882 9.8572 16.9793 13.1429 22.6703 9.8572 22.6703 3.2857 16.9793 0" />
          <polygon points="8.438 11.498 14.1328 14.7841 5.6891 19.659 .0015 16.3688 0 9.8053 8.4437 4.9303 8.438 11.498" />
          <polygon points="5.6946 36.1661 11.3857 32.8804 11.3857 26.309 5.6946 23.0232 .0036 26.309 .0036 32.8804 5.6946 36.1661" />
          <polygon points="14.2359 24.6682 8.5412 21.3821 16.9848 16.5071 22.6724 19.7973 22.6739 26.3609 14.2302 31.2358 14.2359 24.6682" />
          <path d="M41.1803,28.074c-6.957,0-9.8906-2.9619-9.8906-8.0742h5.252c0,2.7939,1.0898,3.8271,4.75,3.8271,2.4863,0,3.5479-.5029,3.5479-1.7324,0-.6982-.5586-1.2285-1.6758-1.5088l-6.5937-1.7314c-3.4082-.9219-4.4697-2.9062-4.4697-5.3926,0-3.8271,2.8496-5.8672,8.0176-5.8672,6.1475,0,9.1924,2.9053,9.1924,7.1807h-5.2246c0-2.124-1.2012-3.0176-3.8838-3.0176-1.8721,0-2.8496.4746-2.8496,1.5928,0,.5586.3076,1.0332,1.5371,1.3682l6.3975,1.5928c3.2686.8105,4.7773,2.6826,4.7773,5.5596,0,3.7441-2.5703,6.2031-8.8838,6.2031Z" />
          <path d="M57.3844,19.4685l4.3027-.4746c0-1.4814-.7266-2.0674-2.627-2.0674-1.7314,0-2.542.7256-2.5977,1.9834h-4.498c.083-3.7158,3.4082-5.6436,7.292-5.6436,3.9111,0,7.2354,1.7598,7.2354,6.3145v3.8828h2.04v4.2188h-3.2412c-1.3691,0-2.1787-.7822-2.1787-2.1514v-1.4248c-.6426,2.0957-2.1514,3.8555-6.2588,3.8555-4.4697,0-5.2246-2.6816-5.2246-4.1348,0-2.458,1.4248-3.9111,5.7559-4.3584ZM58.0827,24.1619c1.9834,0,3.6045-1.0615,3.6045-2.7656l-3.4092.5029c-1.2568.1953-1.9551.335-1.9551,1.1172,0,.9229.8379,1.1455,1.7598,1.1455Z" />
          <path d="M70.2907,13.5457h4.7783v3.0176c.6699-1.7607,2.0391-3.2969,4.6934-3.2969,2.3467,0,3.5205,1.2568,4.0791,3.1289.7822-1.7324,2.375-3.1289,5.1689-3.1289,4.1348,0,5.168,3.1846,5.168,7.292v7.124h-4.7773v-6.8447c0-2.6543-.4189-3.3525-1.8994-3.3525-2.459,0-2.8779,1.9277-2.8779,5.4482v4.749h-4.8057l.0283-.084v-6.7607c0-2.6543-.4189-3.3525-1.9004-3.3525-2.458,0-2.877,1.9277-2.877,5.4482v4.749h-4.7783v-14.1367Z" />
          <path d="M103.5104,13.2664c4.8887,0,7.7109,2.6543,7.7109,7.0967v1.2568h-10.4209c.167,1.7598,1.2012,2.7383,2.9336,2.7383,1.4805,0,2.4863-.5869,2.7656-1.6768h4.75c-.3916,3.1289-3.1572,5.2803-7.5156,5.2803-4.7783,0-7.7393-2.9893-7.7393-7.4033,0-4.5537,3.1289-7.292,7.5156-7.292ZM106.5553,19.1609c-.1396-1.5645-1.2295-2.4307-2.9053-2.4307-1.6484,0-2.7383.8662-2.8496,2.4307h5.7549Z" />
          <path d="M112.7848,20.5857c0-3.9668,1.9277-7.3193,6.5098-7.3193,3.0732,0,4.582,1.4805,5.3643,2.9336V7.1472h4.7773v20.5352h-4.7773v-2.6816c-.8662,1.5918-2.4307,2.9609-5.3643,2.9609-4.582,0-6.5098-3.3799-6.5098-7.376ZM121.1384,23.7429c2.1797,0,3.4932-1.0615,3.4932-3.1572,0-2.0391-1.3135-3.1006-3.4932-3.1006-2.2627,0-3.5762,1.0332-3.5762,3.1006,0,2.124,1.3135,3.1572,3.5762,3.1572Z" />
          <path d="M137.2878,19.4685l4.3027-.4746c0-1.4814-.7266-2.0674-2.627-2.0674-1.7314,0-2.542.7256-2.5977,1.9834h-4.498c.083-3.7158,3.4082-5.6436,7.292-5.6436,3.9111,0,7.2354,1.7598,7.2354,6.3145v3.8828h2.04v4.2188h-3.2412c-1.3691,0-2.1787-.7822-2.1787-2.1514v-1.4248c-.6426,2.0957-2.1514,3.8555-6.2588,3.8555-4.4697,0-5.2246-2.6816-5.2246-4.1348,0-2.458,1.4248-3.9111,5.7559-4.3584ZM137.986,24.1619c1.9834,0,3.6045-1.0615,3.6045-2.7656l-3.4092.5029c-1.2568.1953-1.9551.335-1.9551,1.1172,0,.9229.8379,1.1455,1.7598,1.1455Z" />
          <path d="M166.0925,27.6824h-.0273v3.4922c0,1.6484-.8945,2.4307-2.459,2.4307h-12.0693v-4.2188h9.751v-1.7041h.0273v-3.3242c-.7266,2.0674-2.5703,3.6035-5.6152,3.6035-3.6885,0-5.8955-2.4863-5.8955-6.9287v-7.4873h4.834v6.7334c0,2.542.6699,3.4639,2.793,3.4639,2.3477,0,3.8838-1.1172,3.8838-5.1123v-5.085h4.7773v14.1367Z" />
        </g>
      </g>
    </svg>
  );
};

SamedayLogoHorizontal.displayName = "SamedayLogoHorizontal";

export const SamedayLogo = ({
  className,
  size,
  height = 32,
  width = 32
}: SamedayLogo) => {
  return (
    <svg
      id="Layer_2"
      height={size || height}
      width={size || width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.6739 36.1661"
    >
      <g id="Components">
        <g id="_9ddc3551-2916-46ed-8351-d296fc292a54_4">
          <polygon points="16.9793 0 11.2882 3.2857 11.2882 9.8572 16.9793 13.1429 22.6703 9.8572 22.6703 3.2857 16.9793 0" />
          <polygon points="8.438 11.498 14.1328 14.7841 5.6891 19.659 .0015 16.3688 0 9.8053 8.4437 4.9303 8.438 11.498" />
          <polygon points="5.6946 36.1661 11.3857 32.8804 11.3857 26.309 5.6946 23.0232 .0036 26.309 .0036 32.8804 5.6946 36.1661" />
          <polygon points="14.2359 24.6682 8.5412 21.3821 16.9848 16.5071 22.6724 19.7973 22.6739 26.3609 14.2302 31.2358 14.2359 24.6682" />
        </g>
      </g>
    </svg>
  );
};

SamedayLogo.displayName = "SamedayLogo";
