import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { X } from "lucide-react";
import {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useState,
} from "react";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";

type ChipsInputProps = {
  type?: string;
  label?: ReactNode;
  placeholder?: string;
  variant?: "default" | "secondary" | "destructive" | "success" | "outline";
  values: string[];
  onChange: (values: string[]) => void;
  validate?: (value: string) => boolean;
  isError?: boolean;
  className?: string;
};

export const ChipsInput = forwardRef<HTMLInputElement, ChipsInputProps>(
  (
    {
      type,
      label,
      variant = "default",
      placeholder,
      values,
      onChange,
      validate,
      isError,
      className,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>("");

    const addChips = useCallback(
      (value: string) => {
        if (!value.trim()) return;

        // Split by commas and filter out empty values
        const newChips = value
          .split(",")
          .map((chip) => chip.trim())
          .filter((chip) => chip !== "");

        // Validate each chip if validate function is provided
        const validChips = validate
          ? newChips.filter((chip) => validate(chip))
          : newChips;

        if (validChips.length > 0) {
          onChange([...values, ...validChips]);
        }
      },
      [onChange, validate, values],
    );

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (
          (e.key === "Enter" || e.key === ",") &&
          target.value.trim() !== ""
        ) {
          e.preventDefault();
          addChips(target.value);
          setInputValue("");
        }
      },
      [addChips],
    );

    const onChipDelete = useCallback(
      (index: number) => {
        onChange(values.filter((_, i) => i !== index));
      },
      [values, onChange],
    );

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    }, []);

    const handleOnBlur = useCallback(() => {
      if (inputValue.trim() !== "") {
        addChips(inputValue);
        setInputValue("");
      }
    }, [inputValue, addChips]);

    const handlePaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedText = e.clipboardData.getData("text");
        if (pastedText.includes(",")) {
          e.preventDefault();
          addChips(pastedText);
        }
      },
      [addChips],
    );

    return (
      <div className={cn("flex flex-col gap-1.5", className)}>
        {label && <Label>{label}</Label>}
        <Input
          ref={ref}
          type={type}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          value={inputValue}
          className={cn(
            isError && "border-destructive focus-within:ring-destructive",
          )}
        />
        <div className={cn("flex flex-row gap-2 rounded-b-md flex-wrap mt-2")}>
          {values.map((chip, index) => (
            <Badge
              key={index}
              variant={variant}
              className="gap-1 cursor-default"
            >
              {chip}
              <X
                className="h-3 w-3 cursor-pointer hover:text-muted"
                onClick={() => onChipDelete(index)}
              />
            </Badge>
          ))}
        </div>
      </div>
    );
  },
);

ChipsInput.displayName = "ChipsInput";
