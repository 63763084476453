import { Slot, WeekDay } from "@types";
import {
  getAMPMTimeString,
  getMillitaryTimeString,
  getMinutes,
} from "@utils/time";
import { MoreVertical } from "lucide-react";
import { Button } from "@components";
import { Checkbox } from "src/common/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/common/components/ui/dropdown-menu";
import { RangeSlider } from "./RangeSlider";
import { FC, memo, useCallback, useState, useRef, useMemo } from "react";

export type TimeSliderProps = {
  day: WeekDay;
  open?: boolean;
  onApplyToAll: (open: boolean, slots: Slot[]) => void;
  onChange: (values: { day: WeekDay; open: boolean; slots: Slot[] }) => void;
  slots: Slot[];
};

const getMarkLabel = (value: number) => {
  // Special case for the 24-hour mark (1440 minutes)
  if (value === 1440) {
    return "24h";
  }

  const hoursMilitary = Math.floor(value / 60);
  const hours =
    hoursMilitary === 0 || hoursMilitary === 12 ? 12 : hoursMilitary % 12;
  const period = hoursMilitary >= 12 ? "pm" : "am";
  return `${hours}${period}`;
};

// Create marks for every 2 hours (120 minutes) for a full 24-hour cycle (0 to 24 hours)
const marks = new Array(13).fill(0).map((_, index) => ({
  label: getMarkLabel(index * 120),
  value: index * 120,
}));

// The 24-hour mark (1440 minutes) is already included in the array above

export const TimeSlider: FC<TimeSliderProps> = memo(
  ({ day, open = true, onApplyToAll, onChange, slots }) => {
    // Memoize the 24-hour slot detection to avoid recalculation on every render
    const is24HoursSlot = useMemo(
      () =>
        slots[0].start === "00:00" &&
        (slots[0].end === "23:59" || slots[0].end === "00:00"),
      [slots],
    );

    const [is24Hours, setIs24Hours] = useState(is24HoursSlot);

    // Store previous hours before enabling 24-hour mode
    const previousHoursRef = useRef<Slot | null>(null);

    // Memoize slider values calculation
    const sliderValues = useMemo(
      () =>
        is24Hours
          ? ([0, 1440] as [number, number])
          : ([
              getMinutes(slots[0].start),
              slots[0].end === "00:00" ? 1440 : getMinutes(slots[0].end),
            ] as [number, number]),
      [is24Hours, slots],
    );

    const checkboxChangeHandler = useCallback(
      (checked: boolean) =>
        onChange({
          day,
          open: checked,
          slots,
        }),
      [day, onChange, slots],
    );

    const sliderChangeHandler = useCallback(
      (value: [number, number]) => {
        const is24HoursMode = value[0] === 0 && value[1] === 1440;
        setIs24Hours(is24HoursMode);

        onChange({
          day,
          open,
          slots: [
            {
              start: getMillitaryTimeString(value[0]),
              // Use 00:00 for the end time when it's exactly 24 hours (1440 minutes)
              end:
                value[1] === 1440 ? "00:00" : getMillitaryTimeString(value[1]),
            },
          ],
        });
      },
      [day, open, onChange],
    );

    const getValue = useCallback((values: [number, number]) => {
      if (values[0] === 0 && values[1] === 1440) {
        return "Open 24 hours";
      }
      const start = getAMPMTimeString(values[0]);
      const end =
        values[1] === 1440
          ? "12:00 AM (next day)"
          : getAMPMTimeString(values[1]);
      return `${start} to ${end}`;
    }, []);

    const applyToAllHandler = useCallback(
      () => onApplyToAll(open, slots),
      [onApplyToAll, open, slots],
    );

    const toggle24HoursHandler = useCallback(() => {
      const new24HoursState = !is24Hours;
      setIs24Hours(new24HoursState);

      if (new24HoursState) {
        // Store current hours before enabling 24-hour mode
        previousHoursRef.current = slots[0];

        onChange({
          day,
          open,
          slots: [
            {
              start: "00:00",
              end: "00:00", // Use 00:00 instead of 23:59 for true 24 hours
            },
          ],
        });
      } else {
        // Restore previous hours if available, otherwise use default business hours
        const defaultStart = previousHoursRef.current?.start || "08:00";
        const defaultEnd = previousHoursRef.current?.end || "18:00";

        onChange({
          day,
          open,
          slots: [
            {
              start: defaultStart,
              end: defaultEnd,
            },
          ],
        });

        // Clear stored hours
        previousHoursRef.current = null;
      }
    }, [day, is24Hours, onChange, open, slots]);

    return (
      <div className="flex flex-row gap-4 items-center w-full">
        <div className="basis-1/6 flex items-center space-x-2">
          <Checkbox
            id={`checkbox-${day}`}
            checked={open}
            onCheckedChange={checkboxChangeHandler}
          />
          <label
            htmlFor={`checkbox-${day}`}
            className="text-sm font-medium capitalize leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {day}
          </label>
        </div>
        <div className="flex-1">
          <RangeSlider
            disabled={!open}
            getValue={getValue}
            label={open ? "Open" : "Closed"}
            marks={marks}
            max={1440}
            min={0}
            onValueChange={sliderChangeHandler}
            step={30}
            value={sliderValues}
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="sm" variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open Menu</span>
              <MoreVertical className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={applyToAllHandler}>
              Apply to All
            </DropdownMenuItem>
            <DropdownMenuItem onClick={toggle24HoursHandler}>
              {is24Hours ? "Set Default Hours" : "Set 24 Hours"}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  },
);
