import { forwardRef } from "react";
import { Textarea } from "@components";
import type { TextareaProps } from "../ui/textarea";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "../ui/form";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const formItemVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "flex flex-col gap-2",
      left: "flex gap-8 items-center",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
  },
});

const formLabelVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "",
      left: "w-[104px] flex-shrink-0",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
  },
});

export type FormTextAreaProps = {
  label?: string;
  helperText?: string;
  labelPlacement?: VariantProps<typeof formItemVariants>["labelPlacement"];
  showCharacterCount?: boolean;
} & TextareaProps;

export const FormTextArea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps & FormTextAreaProps
>(
  (
    {
      label,
      helperText,
      labelPlacement,
      className,
      showCharacterCount,
      maxLength,
      value = "",
      ...textareaProps
    },
    ref,
  ) => {
    return (
      <FormItem className={cn(formItemVariants({ labelPlacement }), className)}>
        <FormLabel className={formLabelVariants({ labelPlacement })}>
          {label}
        </FormLabel>
        <div className={cn(labelPlacement === "left" ? "flex-1" : "")}>
          <FormControl>
            <Textarea
              ref={ref}
              maxLength={maxLength}
              value={value}
              {...textareaProps}
            />
          </FormControl>
          {showCharacterCount && maxLength && (
            <div className="text-xs text-muted-foreground text-right mt-1">
              {String(value).length}/{maxLength}
            </div>
          )}
          <FormDescription>{helperText}</FormDescription>
          <FormMessage />
        </div>
      </FormItem>
    );
  },
);

FormTextArea.displayName = "FormTextArea";
