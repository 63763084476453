import { memo } from "react";
import { Skeleton } from "@/components/ui/skeleton";

export const SkeletonPriorities = memo(() => {
  return (
    <div className="flex flex-col gap-4">
      <Skeleton className="w-full h-10 rounded-md" />
      <Skeleton className="w-full h-10 rounded-md" />
      <Skeleton className="w-full h-10 rounded-md" />
      <Skeleton className="w-full h-10 rounded-md" />
      <hr className="w-full my-2 border-t border-gray-200 dark:border-gray-700" />
      <Skeleton className="w-full h-10 rounded-md" />
      <Skeleton className="w-full h-10 rounded-md" />
      <Skeleton className="w-full h-10 rounded-md" />
      <Skeleton className="w-full h-10 rounded-md" />
    </div>
  );
});
