import { ElementRef, ReactNode, forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "../ui/form";
import { Checkbox } from "../ui/checkbox";
import { cn } from "@/lib/utils";

type FormCheckboxInputProps = {
  helperText?: string;
  label?: ReactNode;
};

export const FormCheckboxInput = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    FormCheckboxInputProps
>(({ checked, className, helperText, label, onCheckedChange }, ref) => {
  return (
    <FormItem className={cn("relative rounded-md p-4", className)}>
      <div className="grid grid-cols-[auto_1fr] gap-x-3 items-center">
        <FormControl className="row-start-1 col-start-1 mt-0.5">
          <Checkbox
            ref={ref}
            checked={checked}
            onCheckedChange={onCheckedChange}
          />
        </FormControl>
        <div className="row-start-1 col-start-2">
          <FormLabel className="cursor-pointer">{label}</FormLabel>
        </div>
        <div className="row-start-2 col-start-2 space-y-1">
          <FormDescription>{helperText}</FormDescription>
          <FormMessage />
        </div>
      </div>
    </FormItem>
  );
});

FormCheckboxInput.displayName = "FormCheckboxInput";
