import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface ServiceAreasRequest {
  accessToken?: string;
  companyId?: string;
}

const fetchServiceAreasByCompany = async ({
  accessToken,
  companyId,
}: ServiceAreasRequest) => {
  if (!companyId) {
    return [];
  }
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/service-areas/${companyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch companies");
  }
};

export const useGetServiceAreasCompanyId = () => {
  const { accessToken, companyId } = useSession();
  const query = useQuery({
    queryKey: ["useGetServiceAreasCompanyId", companyId],
    enabled: !!companyId,
    queryFn: () => fetchServiceAreasByCompany({ accessToken, companyId }),
  });

  return query;
};
