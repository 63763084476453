import { memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";

import { useGetCompanyPhoneStatus, usePatchCompany } from "@services";
import { SmsConfig } from "@types";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Button,
  FormSelectInput,
  FormSwitchInput,
  FormTextArea,
} from "@/components";
import { Form, FormField } from "@/components/ui/form";
import { SelectItem } from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
type SmsFormProps = {
  config: SmsConfig;
};

const schema = z.object({
  isActiveCampaign: z.boolean(),
  isConfirmationEnabled: z.boolean(),
  callBackConversationStarter: z
    .string()
    .min(1, "Initial text message is required."),
  timeDelay: z
    .number()
    .min(0, "Time delay must be greater than or equal to 0."),
});

export const SmsForm = memo(({ config }: SmsFormProps) => {
  const [checked, setChecked] = useState(config.hasConsent);
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      isActiveCampaign: config.isActiveCampaign,
      callBackConversationStarter: config.callBackConversationStarter,
      timeDelay: config.timeDelay,
      isConfirmationEnabled: config.isConfirmationEnabled,
    },
  });

  const patchCompany = usePatchCompany();
  const phoneStatus = useGetCompanyPhoneStatus();

  const changeExplicitConsents = useCallback(
    () => setChecked((prev) => !prev),
    [],
  );

  const handleDeleteNumber = useCallback(() => {
    patchCompany.mutate({ sms: { ...config, phone: null, phoneId: null } });
  }, [patchCompany, config]);

  const onSubmit = useCallback(
    (data: Partial<SmsConfig>) => {
      patchCompany.mutate(
        {
          sms: {
            ...data,
            phoneId: config.phoneId,
            phone: config.phone,
            hasConsent: checked,
            brand: config.brand,
            campaign: config.campaign,
            status: config.status,
          },
        },
        {
          onSuccess: () => {
            toast.success("SMS configuration updated successfully");
          },
          onError: () => {
            toast.error("Failed to update SMS configuration");
          },
        },
      );
    },
    [config, patchCompany, checked],
  );

  return (
    <div className="p-6 mx-auto bg-white rounded-md">
      <div className="flex items-center bg-green-100 p-4 rounded-md gap-4">
        <Checkbox
          className="data-[state=checked]:bg-success data-[state=checked]:text-success-foreground"
          checked={checked}
          onCheckedChange={changeExplicitConsents}
        />
        <Icon icon="ic:round-info" className="text-black text-3xl" />
        <p>
          I acknowledge that I have obtained explicit consent from callers to my
          business to also receive automated texts for marketing and sales
          purposes in compliance with{" "}
          <a
            href="https://www.fcc.gov/general/telecommunications-consumer-protection-act"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Telephone Consumer Protections Act (TCPA)
          </a>
        </p>
      </div>

      <div className="my-6">
        <h6 className="text-xl">Generate Texting Number</h6>
        <p className="text-gray-500 mt-1">
          Get a texting number based off your area code
        </p>
        <div className="mt-4 flex items-center gap-4">
          <Badge variant="outline">
            <Icon icon="mdi:circle-medium" className="text-success text-3xl" />
            <p>
              {`(${String(config.phone).slice(2, 5)}) ${String(config.phone).slice(5, 8)}-${String(config.phone).slice(8)}`}
            </p>
          </Badge>
          <Button variant="outlined-destructive" onClick={handleDeleteNumber}>
            Delete Number
          </Button>
        </div>
      </div>

      {config.status !== "ACTIVE" && phoneStatus.data !== "ACTIVE" ? (
        <div>Phone number is not active yet.</div>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-4 flex items-center justify-between">
              <div>
                <h6 className="text-xl">Send Text Messages</h6>
                <p className="text-gray-500 mt-1">
                  When 'active,' Agent will text customers after phone calls
                </p>
              </div>
              <FormField
                name="isActiveCampaign"
                control={form.control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <FormSwitchInput
                    ref={ref}
                    disabled={!checked}
                    checked={value}
                    onCheckedChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <div className="mb-4 flex items-center justify-between">
              <div>
                <h6 className="text-xl">
                  Send Appointment Confirmation Message
                </h6>
                <p className="text-gray-500 mt-1">
                  When 'active', Agent will send the confirmation message if the
                  appointment is scheduled.
                </p>
              </div>
              <FormField
                name="isConfirmationEnabled"
                control={form.control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <FormSwitchInput
                    disabled={!checked}
                    ref={ref}
                    checked={value}
                    onCheckedChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <div className="mb-4">
              <h6 className="text-xl">Initial Text Message</h6>
              <FormField
                name="callBackConversationStarter"
                control={form.control}
                render={({ field }) => (
                  <FormTextArea disabled={!checked} rows={3} {...field} />
                )}
              />
            </div>

            <div className="mb-4 flex items-center justify-between">
              <div>
                <h6 className="text-xl">Time Delay</h6>
                <p className="text-gray-500 mt-1">
                  When to send the message after the call ends
                </p>
              </div>
              <FormField
                name="timeDelay"
                control={form.control}
                render={({ field: { value, ...fieldProps } }) => (
                  <FormSelectInput
                    {...fieldProps}
                    value={String(value)}
                    disabled={!checked}
                  >
                    <SelectItem value="0">Immediately</SelectItem>
                    <SelectItem value="5">5 minutes</SelectItem>
                    <SelectItem value="10">10 minutes</SelectItem>
                  </FormSelectInput>
                )}
              />
            </div>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </form>
        </Form>
      )}
    </div>
  );
});
