import { forwardRef, useState } from "react";
import type { ComponentProps, ReactNode } from "react";
import { Input } from "@components";
import type { InputProps } from "../ui/input";
import { Copy, Check } from "lucide-react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";

const formItemVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "flex flex-col gap-2",
      left: "flex gap-8 items-center",
    },
    hideLabel: {
      true: "!gap-0",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

const formLabelVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "",
      left: "w-[104px] flex-shrink-0",
    },
    hideLabel: {
      true: "hidden",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

interface FormTextInputProps extends VariantProps<typeof formItemVariants> {
  label?: string;
  helperText?: ReactNode;
  className?: string;
  hideLabel?: boolean;
  showCopy?: boolean;
  hideFormMessage?: boolean;
  "data-testid"?: string;
}

export const FormTextInput = forwardRef<
  HTMLInputElement,
  ComponentProps<"input"> & InputProps & FormTextInputProps
>(
  (
    {
      label,
      helperText,
      className,
      labelPlacement,
      hideLabel,
      showCopy = false,
      hideFormMessage = false,
      "data-testid": testId,
      ...inputProps
    },
    ref,
  ) => {
    const [copied, setCopied] = useState(false);
    const inputId = `${label?.toLowerCase().replace(/\s+/g, "-")}-input`;

    const handleCopy = async () => {
      if (inputProps.value != null) {
        try {
          // Safely convert value to string, handling different types
          const textToCopy =
            typeof inputProps.value === "object"
              ? String(inputProps.value) // Falls back to [object Object] instead of exposing internals
              : String(inputProps.value);

          await navigator.clipboard.writeText(textToCopy);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } catch (error) {
          console.error("Failed to copy to clipboard:", error);
        }
      }
    };
    return (
      <FormItem
        className={cn(
          formItemVariants({ labelPlacement, hideLabel }),
          className,
        )}
      >
        <FormLabel
          htmlFor={inputId}
          className={formLabelVariants({ labelPlacement, hideLabel })}
        >
          {label}
        </FormLabel>
        <div className={cn(labelPlacement === "left" ? "flex-1" : "")}>
          <FormControl>
            <div className="relative">
              <Input
                ref={ref}
                id={inputId}
                className={cn(showCopy && "pr-10")}
                data-testid={testId}
                aria-label={label}
                {...inputProps}
              />
              {showCopy && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 hover:bg-transparent"
                  onClick={handleCopy}
                >
                  {copied ? (
                    <Check className="h-4 w-4 text-green-500" />
                  ) : (
                    <Copy className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                  )}
                  <span className="sr-only">Copy Value</span>
                </Button>
              )}
            </div>
          </FormControl>
          {helperText && <FormDescription>{helperText}</FormDescription>}
          {!hideFormMessage && <FormMessage />}
        </div>
      </FormItem>
    );
  },
);
