import { Icon } from "@iconify/react";
import { LinkButton } from "./ui/link-button";
import { Link } from "react-router-dom";
import { useUI } from "@store";
import { NavigationItem } from "@types";
import clsx from "clsx";
import { FC, memo } from "react";

export type NavigationListItemProps = {
  item: NavigationItem;
  selected: boolean;
};

export const NavigationListItem: FC<NavigationListItemProps> = memo(
  ({ item, selected }) => {
    const { closeSidebar } = useUI();
    return (
      <LinkButton
        as={Link}
        className={clsx("justify-start w-full", { "text-foreground": !selected })}
        to={item.href}
        isDisabled={item.disabled}
        onClick={closeSidebar}
        leftIcon={<Icon height={20} icon={item.icon as string} />}
        variant={selected ? "primary" : "ghost"}
      >
        {item.title}
      </LinkButton>
    );
  },
);
