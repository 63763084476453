import { FC, memo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { Button, FormChipsTextInput } from "@components";
import { useBlocker } from "react-router-dom";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Form, FormField } from "@/components/ui/form";

export type CompanyServiceAreasFormValues = {
  serviceAreas: string[];
};

export type CompanyInfoFormProps = {
  onSubmit: (values: CompanyServiceAreasFormValues) => void;
  serviceAreas: string[];
  pending: boolean;
};

// Custom validator for 5-digit zip codes
const validateZipCode = (value: string) => /^\d{5}$/.test(value);

const formSchema = z.object({
  serviceAreas: z
    .array(z.string())
    .refine((areas) => areas.every(validateZipCode), {
      message: "Service area must be a 5 digit code",
    })
    .refine(
      (areas) => {
        const uniqueAreas = new Set(areas);
        return uniqueAreas.size === areas.length;
      },
      {
        message: "Service areas must be unique",
      },
    ),
});

export const CompanyServiceAreasForm: FC<CompanyInfoFormProps> = memo(
  ({ serviceAreas, onSubmit, pending }) => {
    const form = useForm<CompanyServiceAreasFormValues>({
      defaultValues: {
        serviceAreas: serviceAreas,
      },
      resolver: zodResolver(formSchema),
      mode: "onChange",
    });

    const {
      control,
      formState: { isDirty, isValid },
      handleSubmit,
    } = form;

    useBlocker(() => {
      if (isDirty) {
        return window.confirm(
          "Are you sure you want to leave this page? You have unsaved changes.",
        );
      } else {
        return false;
      }
    });

    return (
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent className="pt-6 flex gap-4 flex-col">
              <FormField
                control={control}
                name="serviceAreas"
                render={({ field }) => (
                  <FormChipsTextInput
                    {...field}
                    type="number"
                    label="Service Areas"
                    values={field.value}
                    variant="secondary"
                    onChange={field.onChange}
                  />
                )}
              />
            </CardContent>
            <CardFooter className="justify-end">
              <Button
                size="sm"
                variant="primary"
                disabled={pending || !isValid}
                type="submit"
              >
                {pending ? "Saving..." : "Save Service Areas"}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    );
  },
);
