import { zodResolver } from "@hookform/resolvers/zod";
import { useBlocker } from "react-router-dom";
import timezones from "@utils/timezones";
import { FC, memo } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@components";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { FormTextInput } from "@/components/FormTextInput/FormTextInput";
import { FormSelectInput } from "@/components/FormSelectInput/FormSelectInput";
import { SelectItem } from "@/components/ui/select";
import { Form, FormField } from "@/components/ui/form";

export type CompanyInfoFormValues = {
  name: string;
  phone: string;
  sipUri: string;
  fallbackPhone?: string;
  timeZone: string;
  webhookUrl?: string;
};

export type CompanyInfoFormProps = {
  name: string;
  onSubmit: (values: CompanyInfoFormValues) => void;
  pending: boolean;
  phone: string;
  sipUri: string;
  fallbackPhone: string;
  timeZone: string;
  webhookUrl: string;
};

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  phone: z.string().refine((value) => /^\+\d{9,15}$/.test(value), {
    message: "Phone number must be in the format + followed by 9 to 15 digits",
  }),
  sipUri: z
    .string()
    .refine((value) => /^sip:([a-zA-Z0-9_-]+)@sip.vapi.ai$/.test(value), {
      message: "SIP Uri must be in the format sip:your_username@sip.vapi.ai.",
    }),
  fallbackPhone: z
    .string()
    .refine((value) => /^\+\d{9,15}$/.test(value), {
      message:
        "Fallback Phone number must be in the format + followed by 9 to 15 digits",
    })
    .optional()
    .or(z.literal("")),
  timeZone: z.string().min(1, "Time zone is required"),
  webhookUrl: z
    .string()
    .url()
    .optional()
    .or(
      z
        .string()
        .trim()
        .refine((value) => value === "", {
          message: "Webhook URL must be a valid URL",
        }),
    ),
});

export const CompanyInfoForm: FC<CompanyInfoFormProps> = memo(
  ({
    name,
    onSubmit,
    pending,
    phone,
    sipUri,
    fallbackPhone,
    timeZone,
    webhookUrl,
  }) => {
    const form = useForm<CompanyInfoFormValues>({
      defaultValues: {
        name: name ?? "",
        phone: phone ?? "",
        sipUri: sipUri ?? "",
        timeZone: timeZone ?? "",
        fallbackPhone: fallbackPhone ?? "",
        webhookUrl: webhookUrl ?? "",
      },
      resolver: zodResolver(formSchema),
    });

    const { isDirty } = form.formState;

    useBlocker(() => {
      if (isDirty) {
        return window.confirm(
          "Are you sure you want to leave this page? You have unsaved changes.",
        );
      } else {
        return false;
      }
    });

    return (
      <Form {...form}>
        <form
          className="mt-8"
          data-testid="company-info"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Card>
            <CardContent className="flex gap-4 flex-col pt-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormTextInput
                    label="Name"
                    data-testid="input-name"
                    {...field}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormTextInput
                    label="Phone"
                    data-testid="input-phone"
                    readOnly
                    {...field}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="sipUri"
                render={({ field }) => (
                  <FormTextInput
                    type="password"
                    label="SIP Uri"
                    data-testid="input-sip-uri"
                    showCopy
                    {...field}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="fallbackPhone"
                render={({ field }) => (
                  <FormTextInput
                    label="Fallback Phone"
                    data-testid="input-fallback-phone"
                    {...field}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="timeZone"
                render={({ field }) => (
                  <FormSelectInput
                    label="Time Zone"
                    data-testid="time-zone"
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    {Object.entries(timezones).map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </FormSelectInput>
                )}
              />

              <FormField
                control={form.control}
                name="webhookUrl"
                render={({ field }) => (
                  <FormTextInput
                    label="Webhook URL"
                    data-testid="input-webhook-url"
                    placeholder="Webhook URL for Zapier, or Other Services"
                    helperText="Data Will Be Sent to This Webhook URL After Each Conversation."
                    {...field}
                  />
                )}
              />
            </CardContent>
            <CardFooter className="justify-end">
              <Button
                size="sm"
                variant="primary"
                disabled={pending}
                type="submit"
              >
                {pending ? "Saving..." : "Save Company Info"}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    );
  },
);
