import { forwardRef } from "react";
import type { ComponentProps } from "react";
import { DateTimePicker } from "../DateTimePicker/DateTimePicker";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";

const formItemVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "flex flex-col gap-2",
      left: "flex gap-8 items-center",
    },
    hideLabel: {
      true: "!gap-0",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

const formLabelVariants = cva("", {
  variants: {
    labelPlacement: {
      top: "",
      left: "w-[104px] flex-shrink-0",
    },
    hideLabel: {
      true: "hidden",
      false: "",
    },
  },
  defaultVariants: {
    labelPlacement: "top",
    hideLabel: false,
  },
});

interface FormDateTimePickerProps
  extends ComponentProps<typeof DateTimePicker>,
    VariantProps<typeof formItemVariants> {
  label?: string;
  helperText?: React.ReactNode;
  className?: string;
  hideLabel?: boolean;
  onBlur?: () => void;
}

export const FormDateTimePicker = forwardRef<
  HTMLButtonElement,
  FormDateTimePickerProps
>(
  (
    {
      label,
      helperText,
      className,
      labelPlacement,
      hideLabel,
      value,
      onChange,
      onBlur,
      ...props
    },
    ref,
  ) => {
    return (
      <FormItem
        className={cn(
          formItemVariants({ labelPlacement, hideLabel }),
          className,
        )}
      >
        <FormLabel className={formLabelVariants({ labelPlacement, hideLabel })}>
          {label}
        </FormLabel>
        <div className={cn(labelPlacement === "left" ? "flex-1" : "")}>
          <FormControl>
            <DateTimePicker
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              {...props}
            />
          </FormControl>
          {helperText && <FormDescription>{helperText}</FormDescription>}
          <FormMessage />
        </div>
      </FormItem>
    );
  },
);

FormDateTimePicker.displayName = "FormDateTimePicker";
