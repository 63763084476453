import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components";

import { BrandInfo } from "@types";
import { usePostBrand } from "@/services/usePostBrand";
import { Form, FormField } from "@/components/ui/form";
import { FormTextInput } from "@/components";

const schema = z.object({
  entityType: z.string().min(1, "Entity Type is required."),
  entityName: z.string().min(1, "Entity Name is required."),
  displayName: z.string().min(1, "Display Name is required."),
  ein: z.string().min(1, "EIN is required"),
  website: z.string().min(1, "Link of brand's website is required."),
  street: z.string().min(1, "Street for brand address is required."),
  city: z.string().min(1, "City for brand address is required"),
  state: z.string().min(1, "State for brand address is required"),
  postalCode: z.string().refine((value) => /^\d{5}$/.test(value), {
    message: "It should be 5 digit postal code.",
  }),
  country: z.string().refine((value) => /^[a-zA-Z]{2}$/.test(value), {
    message: "It should be 2 letter country code",
  }),
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email(),
  phone: z.string().refine((value) => /^\+\d{11}$/.test(value), {
    message: "Phone number must be in the format + followed by 11 digits",
  }),
});

export const BrandForm = memo(() => {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      entityType: "PRIVATE_PROFIT",
      entityName: "",
      displayName: "",
      ein: "",
      website: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });

  const postBrand = usePostBrand();

  const onSubmit = useCallback(
    (data: BrandInfo) => {
      postBrand.mutate(data, {
        onSuccess: () => {
          toast.success("Brand created successfully");
        },
        onError: (error: Error) => {
          toast.error(error.message);
        },
      });
    },
    [postBrand],
  );

  return (
    <div className="p-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-12 flex justify-between">
            <div className="flex flex-col w-2/3">
              <h6 className="text-xl">Register Your Brand</h6>
              <p className="text-gray-500 mt-1">
                It's required that you register your brand, including address
                and tax id before you create unique SMS campaigns. Failing to do
                so will cause phone carries to block incoming texts.
              </p>
            </div>

            <div>
              <Button type="submit" variant="primary">
                Submit Brand Registration
              </Button>
            </div>
          </div>
          <div className="mb-12 flex flex-col justify-between gap-4 w-2/3">
            <div className="flex gap-4">
              <FormField
                name="entityType"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput disabled {...field} label="Entity Type" />
                )}
              />
              <FormField
                name="entityName"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="Entity Name" />
                )}
              />
              <FormField
                name="displayName"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="Brand Name/DBA" />
                )}
              />
            </div>
            <FormField
              name="website"
              control={form.control}
              render={({ field }) => (
                <FormTextInput {...field} label="Business Website" />
              )}
            />
          </div>

          <div className="mb-12 flex flex-col justify-between gap-4 w-2/3">
            <h6 className="text-xl">Legal Business Address</h6>
            <FormField
              name="street"
              control={form.control}
              render={({ field }) => (
                <FormTextInput {...field} label="Street" />
              )}
            />
            <div className="flex gap-4">
              <FormField
                name="city"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="City" />
                )}
              />
              <FormField
                name="state"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="State" />
                )}
              />
            </div>
            <div className="flex gap-4">
              <FormField
                name="country"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="Country" />
                )}
              />
              <FormField
                name="postalCode"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="Postal Code" />
                )}
              />
            </div>
          </div>

          <div className="mb-12 flex flex-col justify-between gap-4 w-1/3">
            <h6 className="text-xl">Business Tax ID</h6>
            <FormField
              name="ein"
              control={form.control}
              render={({ field }) => (
                <FormTextInput {...field} label="EIN Number" />
              )}
            />
          </div>

          <div className="flex flex-col justify-between gap-4 w-2/3">
            <h6 className="text-xl">Contact Information</h6>
            <div className="flex gap-4">
              <FormField
                name="firstName"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="First Name" />
                )}
              />
              <FormField
                name="lastName"
                control={form.control}
                render={({ field }) => (
                  <FormTextInput {...field} label="Last Name" />
                )}
              />
            </div>
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => <FormTextInput {...field} label="Email" />}
            />
            <FormField
              name="phone"
              control={form.control}
              render={({ field }) => <FormTextInput {...field} label="Phone" />}
            />
          </div>
        </form>
      </Form>
    </div>
  );
});
