import { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "@config";
import { useCompaniesById, useGetAccountById } from "@services";
import { useSession } from "@store";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import { CompanySelector } from "./company-selector";
import { Skeleton } from "../ui/skeleton";
import { NavigationItem } from "@/types";
import { SamedayLogo } from "@/assets";

const adminItems = {
  title: config.navigation.sidebar[0].title,
  items: config.navigation.sidebar[0].items,
};

const platformItems = {
  title: config.navigation.sidebar[1].title,
  items: config.navigation.sidebar[1].items,
};

const settingsItems = {
  title: config.navigation.sidebar[2].title,
  items: config.navigation.sidebar[2].items,
};

export const MainNav = memo(() => {
  const { open } = useSidebar();
  const { user, userHasAccess } = useSession();
  const company = useCompaniesById();
  const account = useGetAccountById();
  const location = useLocation();

  const multiCompanyOwner = useMemo(
    () => user?.role === "owner" && account?.data?.type === "MultiCompany",
    [user?.role, account?.data?.type],
  );

  const showSuperItems = useMemo(
    () => user?.role === "super" || multiCompanyOwner,
    [user?.role, multiCompanyOwner],
  );

  // Cache filtered admin items
  const filteredAdminItems = useMemo(
    () =>
      adminItems.items.filter((item: NavigationItem) => {
        if (item.href === "/company-selection") {
          return user?.role === "super" || multiCompanyOwner;
        }
        return userHasAccess(item);
      }),
    [user?.role, multiCompanyOwner, userHasAccess],
  );

  // Cache filtered platform items
  const filteredPlatformItems = useMemo(
    () =>
      platformItems.items.filter((item: NavigationItem) => userHasAccess(item)),
    [userHasAccess],
  );

  // Cache filtered settings items
  const filteredSettingsItems = useMemo(
    () =>
      settingsItems.items.filter((item: NavigationItem) => userHasAccess(item)),
    [userHasAccess],
  );

  const renderMenuGroup = (
    menuGroup: typeof adminItems | typeof platformItems | typeof settingsItems,
    filteredItems: NavigationItem[],
  ) => {
    if (filteredItems.length === 0) return null;
    return (
      <SidebarGroup>
        <div
          className={cn(
            "px-4 py-2 text-sm font-semibold text-sidebar-foreground-group-header",
            !open && "invisible opacity-0",
          )}
        >
          {menuGroup.title}
        </div>
        <SidebarGroupContent>
          <SidebarMenu>{renderMenuItems(filteredItems)}</SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
    );
  };

  const renderMenuItems = (items: typeof platformItems.items) => {
    return items.map((item) => {
      const currentPath = location.pathname;

      // Updated active check logic
      const isActive =
        item.href !== "/" &&
        (() => {
          // Exact match
          if (currentPath === item.href) return true;

          // Check if current path starts with item href AND next char is "/"
          if (currentPath.startsWith(item.href + "/")) return true;

          return false;
        })();

      return (
        <SidebarMenuItem key={item.href}>
          <SidebarMenuButton asChild isActive={isActive}>
            <Link
              className={cn(
                "relative flex h-10 items-center px-4 transition-all duration-150 ease-out",
              )}
              to={item.href}
            >
              <item.icon className={cn("h-[18px] w-[18px]")} />
              <span className={cn("ml-3 transition-all duration-150 ease-out")}>
                {item.title}
              </span>
              {isActive && !open && (
                <div className="absolute inset-y-0 left-0 w-1 bg-sidebar-foreground" />
              )}
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      );
    });
  };
  return (
    <Sidebar variant="sidebar" collapsible="icon">
      <SidebarHeader>
        <div className="flex items-center gap-2">
          <div className="flex shrink-0 h-8 items-center justify-center">
            <SamedayLogo className="h-8 w-8 fill-foreground" />
          </div>
          {open && (
            <span
              className={cn(
                "self-end text-2xl text-foreground font-ppNeueMachinaBold transition-all duration-150 ease-out",
              )}
            >
              Sameday
            </span>
          )}
        </div>
        {showSuperItems && (
          <>
            {company.data ? (
              <CompanySelector className="mt-4" company={company.data} />
            ) : (
              <Skeleton className="w-full h-[66px] mt-4" />
            )}
          </>
        )}
        
      </SidebarHeader>
      <SidebarContent>
        {showSuperItems && renderMenuGroup(adminItems, filteredAdminItems)}
        {renderMenuGroup(platformItems, filteredPlatformItems)}
        {renderMenuGroup(settingsItems, filteredSettingsItems)}
      </SidebarContent>
    </Sidebar>
  );
});
