import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { formatPhoneNumber } from "@/utils/phoneNumber";
import { memo, useCallback, useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, Copy } from "lucide-react";

type PhoneNumberDisplayProps = {
  phoneNumber: string;
  className?: string;
}

const phoneNumberDisplayVariants = cva(
  "",
  {
    variants: {
      status: {
        "active": "bg-success-500",
        "inactive": "bg-warning-400",
        "pending": "bg-warning-400",
        "default": "bg-gray-500"
      },
    },
    defaultVariants: {
      status: "active",
    },
  },
);

export const PhoneNumberDisplay = memo(({
  phoneNumber,
  status,
  className = "",
}: PhoneNumberDisplayProps & VariantProps<typeof phoneNumberDisplayVariants>) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(phoneNumber);
    setCopied(true);
    const timeout = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timeout);
  }, [phoneNumber]);

  return (
    <Button
      variant="outlined"
      className={cn("rounded-sm", className)}
      size="md"
      onClick={handleCopy}
    >
      <div className={cn(phoneNumberDisplayVariants({ status }), 'w-2 h-2 rounded-full')} />
      {formatPhoneNumber(phoneNumber)}
      {copied ? (
        <Check className="h-4 w-4 text-green-500 ml-2" />
      ) : (
        <Copy className="h-4 w-4 text-gray-400 hover:text-gray-600 ml-2" />
      )}
    </Button>
  );
});