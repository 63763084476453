import { BrandInfo } from "@/types";
import { useSession } from "@store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const postCreateBrand = async (
  data: BrandInfo,
  accessToken?: string,
  companyId?: string,
) => {
  try {
    const response = await axios.post(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/${companyId}/brand`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error creating brand", error);
    if (axios.isAxiosError(error)) {
      throw Error(error.response?.data.detail);
    }
    throw error;
  }
};

export const usePostBrand = () => {
  const queryClient = useQueryClient();
  const { accessToken, companyId } = useSession();
  return useMutation({
    mutationFn: (params: BrandInfo) =>
      postCreateBrand(params, accessToken, companyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["useCompaniesById", companyId],
      });
    },
  });
};
