import { useEffect, useRef } from "react";

interface ChatWidgetConfig {
  userBubble?: string;
  aiBubble?: string;
  backGround?: string;
  button?: string;
  header?: string;
  nameHeader?: string;
  agentName?: string;
  initialMessage?: string;
  speechBubbleMessage?: string;
}

interface ChatWidgetProps {
  secret: string;
  companyId: string;
  config: ChatWidgetConfig;
}

const endpoint = `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/chat-widget/interaction`;

export const ChatWidget = ({ config, secret, companyId }: ChatWidgetProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // Clean up any existing widget
    if (containerRef.current) {
      containerRef.current.innerHTML = "";
    }

    // Remove any existing script
    if (scriptRef.current) {
      try {
        if (document.head.contains(scriptRef.current)) {
          document.head.removeChild(scriptRef.current);
        }
      } catch (error) {
        console.error("Error removing script:", error);
      }
      scriptRef.current = null;
    }

    // Clean up any existing widget elements from the DOM
    const existingContainer = document.getElementById(
      "sameday-chat-widget-container",
    );
    if (existingContainer) {
      existingContainer.remove();
    }

    // Validate required configuration
    if (!endpoint) {
      console.error("Chat widget endpoint is required");
      return;
    }

    if (!companyId || companyId.trim() === "") {
      console.error("Chat widget companyId is required and cannot be empty");
      return;
    }

    if (!secret || secret.trim() === "") {
      console.warn("Chat widget secret key is empty or missing");
      // Continue anyway as this might be intentional in some cases
    }

    // Set the global configuration
    window.SAMEDAY_CHAT_CONFIG = {
      ...config,
      companyId,
      secret,
      endpoint,
      // Generate a unique session ID for each initialization
      sessionId: "session_" + crypto.randomUUID(),
    };

    // Determine the script URL from the endpoint using URL object for safer manipulation
    const scriptUrl = (() => {
      try {
        const url = new URL(endpoint);
        // Replace the path part while keeping the origin
        url.pathname = "/static/widget.js";
        return url.toString();
      } catch (error) {
        console.error("Invalid endpoint URL:", error);
        return `${endpoint.replace(/\/+$/, "")}/static/widget.js`;
      }
    })();

    // Create a new script element
    const script = document.createElement("script");
    script.src = scriptUrl;
    script.defer = true;
    script.async = true;
    script.onload = () => {
      console.log("Chat widget script loaded successfully");
    };
    script.onerror = (error) => {
      console.error("Failed to load chat widget script:", error);
    };

    // Add the script to the document
    document.head.appendChild(script);
    scriptRef.current = script;

    // Cleanup function
    return () => {
      // Remove the script when the component unmounts
      if (scriptRef.current) {
        try {
          if (document.head.contains(scriptRef.current)) {
            document.head.removeChild(scriptRef.current);
          }
        } catch (error) {
          console.error("Error removing script on cleanup:", error);
        }
      }

      // Remove the widget container
      const widgetContainer = document.getElementById(
        "sameday-chat-widget-container",
      );
      if (widgetContainer) {
        widgetContainer.remove();
      }

      // Delete the global configuration
      delete window.SAMEDAY_CHAT_CONFIG;
    };
  }, [config, companyId, secret]);

  return <div id="sameday-chat-widget" ref={containerRef}></div>;
};

// Add TypeScript declaration for the global SAMEDAY_CHAT_CONFIG
declare global {
  interface Window {
    SAMEDAY_CHAT_CONFIG?: ChatWidgetConfig & {
      sessionId?: string;
      companyId?: string;
      secret?: string;
      endpoint?: string;
    };
  }
}
