import { memo, useCallback, useMemo, useState } from "react";
import { Building2, Check, ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useSidebar } from "@/components/ui/sidebar";
import { Company } from "@types";
import { useGetAccountById, useGetCompanies } from "@services";
import { useSession } from "@store";
import { formatPhoneNumber } from "@/utils/phoneNumber";
import { useDebounce } from "@uidotdev/usehooks";

type CompanySelectorProps = {
  className?: string;
  company?: Company;
};

export const CompanySelector = memo(
  ({ className, company }: CompanySelectorProps) => {
    const { open } = useSidebar();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 300);
    const { setCompanyId, userHasRole } = useSession();
    const companies = useGetCompanies({ name: debouncedSearch });

    const account = useGetAccountById();

    const items = useMemo(() => {
      const baseItems = userHasRole("super")
        ? companies.data?.items || []
        : account.data?.companies || [];

      if (
        company &&
        !baseItems.some((item: Company) => item._id === company._id)
      ) {
        return [...baseItems, company];
      }

      return baseItems;
    }, [userHasRole, companies.data?.items, account.data?.companies, company]);

    const [selectedId, setSelectedId] = useState<string>(company?._id || "");

    const setSelectedCompanyHandler = useCallback(
      (companyId: string) => {
        setCompanyId(companyId);
        setSelectedId(companyId);
      },
      [setCompanyId],
    );

    const renderMenuItem = (item: Company) => (
      <CommandItem
        key={item._id}
        value={item.name}
        onSelect={() => {
          setSelectedCompanyHandler(item._id);
          setIsOpen(false);
        }}
      >
        <Check
          className={cn(
            "mr-2 h-4 w-4",
            selectedId === item._id ? "opacity-100" : "opacity-0",
          )}
        />
        {item.name}
      </CommandItem>
    );

    return (
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className={className} asChild>
          <button
            role="combobox"
            aria-expanded={isOpen}
            className="rounded-sm border border-input bg-background flex w-full items-center gap-3 p-3"
            style={{ visibility: open ? "visible" : "hidden" }}
          >
            <div className="flex h-[34px] w-[34px] items-center justify-center rounded-lg bg-zinc-900 shrink-0">
              <Building2 className="h-[18px] w-[18px] text-white" />
            </div>
            {company ? (
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium max-w-[140px] truncate">
                  {company?.name || ""}
                </p>
                <p className="text-sm text-muted-foreground max-w-[140px] truncate">
                  {formatPhoneNumber(company?.phone || "")}
                </p>
              </div>
            ) : (
              "Select Location"
            )}
            <ChevronDown className="ml-auto h-4 w-4" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-[240px] p-0">
          <Command>
            <CommandInput
              placeholder="Search Location..."
              value={search}
              onValueChange={setSearch}
            />
            <CommandList>
              <CommandEmpty>No Location Found.</CommandEmpty>
              <CommandGroup>
                {items?.map((item: Company) => renderMenuItem(item))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);
