import { MainNav } from "@/components/MainNavigation/main-nav";
import { TopHeader } from "@/components/MainNavigation/top-header";
import { SidebarProvider } from "@/components/ui/sidebar";
import { getCookie } from "@/lib/utils";
import { ErrorInfo, PageHeader } from "@components";
import { useScreen } from "@hooks";
import { useSession, useUI } from "@store";
import { PendoWindow, TabItem } from "@types";
import { FC, ReactNode, memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { InactiveCompanyBanner } from "@libs/Company";

export type AuthenticatedLayoutProps = {
  actions?: ReactNode;
  children: ReactNode;
  icon?: ReactNode;
  description?: string;
  showPhoneNumber?: boolean;
  tabs?: TabItem[];
  title: string;
};

export const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = memo(
  ({ actions, children, description, icon, showPhoneNumber, title, tabs }) => {
    const location = useLocation();
    const { userIsAuthorized, user } = useSession();
    const { isWide } = useScreen();
    const { openSidebar } = useUI();
    const defaultOpen = getCookie("sidebar_state") === "true";
    // Handles the edge case of initial load
    const defaultCollapsed = !defaultOpen
      ? true
      : getCookie("sidebar_collapsed_state") === "true";

    useEffect(() => {
      isWide && openSidebar();
    }, [isWide, openSidebar]);

    useEffect(() => {
      try {
        const pendoWindow = window as PendoWindow;
        if (pendoWindow?.pendo?.initialize && user) {
          pendoWindow.pendo.initialize({
            visitor: {
              id: user?.id,
              email: user?.email,
              role: user?.role,
            },
            account: {
              id: user?.companyId,
            },
          });
        }
      } catch (error) {
        console.warn("Failed to initialize Pendo:", error);
      }
    }, [user]);

    return (
      <SidebarProvider
        defaultCollapsed={defaultCollapsed}
        defaultOpen={defaultOpen}
      >
        <div className="flex h-full w-full overflow-hidden">
          <MainNav />
          <main className="flex flex-col flex-1 w-full overflow-hidden">
            <TopHeader />
            <InactiveCompanyBanner />
            <PageHeader
              actions={actions}
              icon={icon}
              description={description}
              tabs={tabs}
              showPhoneNumber={showPhoneNumber}
              title={title}
            />
            <div className="flex-1 min-h-0 w-full bg-muted-background">
              {userIsAuthorized(location.pathname) ? (
                children
              ) : (
                <ErrorInfo
                  description="You are not authorized"
                  subtitle="Access denied"
                  title="Forbidden"
                />
              )}
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  },
);
