import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { memo, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TabItem } from "@types";
import { useSession } from "@store";

type PageTabsProps = {
  tabs?: TabItem[];
};

export const PageTabs = memo(({ tabs }: PageTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userHasRole } = useSession();

  const currentTab = useMemo(() => location.pathname, [location.pathname]);

  const handleTabChange = useCallback(
    (value: string) => {
      if (tabs) {
        navigate(value);
      }
    },
    [navigate, tabs],
  );

  // Filter tabs based on user roles
  const filteredTabs = useMemo(() => {
    if (!tabs) return [];

    return tabs.filter((tab) => {
      // If no roles are specified, show the tab to everyone
      if (!tab.roles) return true;

      // Check if the user has any of the required roles
      return Array.from(tab.roles).some((role) => userHasRole(role));
    });
  }, [tabs, userHasRole]);

  if (!filteredTabs || filteredTabs.length === 0) return null;

  return (
    <Tabs value={currentTab} onValueChange={handleTabChange}>
      <TabsList variant="default">
        {filteredTabs.map((tab) => (
          <TabsTrigger
            disabled={tab.disabled}
            key={tab.path}
            variant="default"
            value={tab.path}
          >
            <p>{tab.label}</p>
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
});