import { forwardRef } from "react";
import { Switch } from "../ui/switch";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "../ui/form";
import { SwitchProps } from "@radix-ui/react-switch";

export type FormSwitchInputProps = {
  label?: string;
  helperText?: string;
} & SwitchProps;

export const FormSwitchInput = forwardRef<
  HTMLButtonElement,
  SwitchProps & FormSwitchInputProps
>(({ label, helperText, ...switchProps }, ref) => {
  return (
    <FormItem>
      <div className="flex flex-col gap-2">
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <Switch ref={ref} {...switchProps} />
        </FormControl>
      </div>
      <FormDescription>{helperText}</FormDescription>
      <FormMessage />
    </FormItem>
  );
});
