import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "src/common/lib/utils";

export interface RangeSliderProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>,
    "value" | "defaultValue"
  > {
  value?: [number, number];
  defaultValue?: [number, number];
  onValueChange?: (value: [number, number]) => void;
  getValue?: (value: [number, number]) => string;
  label?: string;
  marks?: { label: string; value: number }[];
}

const RangeSlider = React.memo(
  React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    RangeSliderProps
  >(
    (
      {
        className,
        value,
        defaultValue = [0, 100],
        onValueChange,
        getValue,
        label,
        marks,
        ...props
      },
      ref,
    ) => {
      const displayValue = React.useMemo(() => {
        if (!getValue || !value) return "";
        return getValue(value);
      }, [getValue, value]);

      // Check if the slider is set to full 24 hours
      const is24Hours = React.useMemo(
        () => value && value[0] === 0 && value[1] === 1440,
        [value],
      );

      return (
        <div className="space-y-2 w-full">
          {(label || displayValue) && (
            <div className="flex justify-between">
              {label && <span className="text-sm font-medium">{label}</span>}
              {displayValue && (
                <span className="text-sm text-muted-foreground">
                  {displayValue}
                </span>
              )}
            </div>
          )}
          <SliderPrimitive.Root
            ref={ref}
            className={cn(
              "relative flex w-full touch-none select-none items-center",
              className,
            )}
            value={value}
            defaultValue={defaultValue}
            onValueChange={onValueChange}
            {...props}
          >
            <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary-200">
              <SliderPrimitive.Range
                className={cn(
                  "absolute h-full bg-secondary-500",
                  is24Hours && "bg-secondary-900",
                )}
              />
            </SliderPrimitive.Track>
            <SliderPrimitive.Thumb
              className={cn(
                "block h-5 w-5 rounded-full border-2 border-secondary-900 bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                is24Hours && "border-secondary-900",
              )}
            />
            <SliderPrimitive.Thumb
              className={cn(
                "block h-5 w-5 rounded-full border-2 border-secondary-900 bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                is24Hours && "border-secondary-900",
              )}
            />
          </SliderPrimitive.Root>

          {marks && marks.length > 0 && (
            <div className="relative w-full pt-1">
              <div className="flex justify-between">
                {marks.map((mark) => (
                  <div
                    key={mark.value}
                    className="flex flex-col items-center"
                    style={{
                      position: "absolute",
                      left: `${(mark.value / props.max!) * 100}%`,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <div className="text-xs text-muted-foreground">
                      {mark.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    },
  ),
);

RangeSlider.displayName = "RangeSlider";

export { RangeSlider };
