import { useCompaniesById } from "@/services";
import { memo } from "react";
import { ChatWidgetEditor } from "./components/ChatWidgetEditor";
import { Skeleton } from "@/components/ui/skeleton";
import { SectionHeader } from "@/components";

export const ChatWidgetPage = memo(() => {
  const company = useCompaniesById();

  // Show loading state while company data is being fetched
  if (company.isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-64 w-full" />
      </div>
    );
  }

  // Show error state if company data fetch failed
  if (company.isError) {
    return (
      <div className="p-4 text-red-500">
        Error loading company data. Please try again later.
      </div>
    );
  }

  // Only render the editor when company data is available
  if (!company?.data?._id) {
    return (
      <div className="p-4">
        No company data available. Please make sure you have selected a company.
      </div>
    );
  }

  // Extract existing widget configuration from company data
  const existingConfig = {
    userBubble:
      company.data?.chatWidgetConfig?.customizableInput["userBubble"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["userBubble"],
    aiBubble:
      company.data?.chatWidgetConfig?.customizableInput["aiBubble"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["aiBubble"],
    backGround:
      company.data?.chatWidgetConfig?.customizableInput["backGround"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["backGround"],
    button:
      company.data?.chatWidgetConfig?.customizableInput["button"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["button"],
    header:
      company.data?.chatWidgetConfig?.customizableInput["header"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["header"],
    nameHeader:
      company.data?.chatWidgetConfig?.customizableInput["nameHeader"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["nameHeader"],
    agentName:
      company.data?.chatWidgetConfig?.customizableInput["agentName"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput["agentName"],
    initialMessage:
      company.data?.chatWidgetConfig?.customizableInput["initialMessage"] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput[
        "initialMessage"
      ],
    speechBubbleMessage:
      company.data?.chatWidgetConfig?.customizableInput[
        "speechBubbleMessage"
      ] ||
      company.data?.chatWidgetConfig?.defaultCustomizableInput[
        "speechBubbleMessage"
      ],
    allowedOrigins:
      company.data?.chatWidgetConfig?.chatWidgetAllowedOrigins || [],
  };

  return (
    <div className="p-8">
      <SectionHeader title="Widget Customization" />
      <ChatWidgetEditor
        initialConfig={existingConfig}
        companyId={company.data?._id}
        secret={company.data?.chatWidgetConfig?.chatWidgetSecretKey}
        htmlSnippet={company.data?.chatWidgetConfig?.htmlSnippet}
      />
    </div>
  );
});

ChatWidgetPage.displayName = "ChatWidgetPage";
