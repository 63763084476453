import { useGetBrandStatus } from "@/services/useGetBrandStatus";
import { memo } from "react";

export const WaitForBrandApprove = memo(() => {
  const brandStatus = useGetBrandStatus();
  return !brandStatus.data ? (
    <div>
      Your brand has been successfully submitted. Please allow a few days for
      the approval process to be completed.
    </div>
  ) : (
    <div>Your brand has been approved. You can now buy the phone number.</div>
  );
});
