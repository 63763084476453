import { forwardRef } from "react"
import type { ReactNode, ComponentProps } from "react"
import { cn } from "@/lib/utils"

export type InputProps = {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

const Input = forwardRef<HTMLInputElement, ComponentProps<"input"> & InputProps>(
  ({
    className,
    leftIcon,
    rightIcon,
    type,
    ...props
  }, ref) => {
    return (
      <div
        className={cn(
          "flex gap-2 items-center h-10 w-full rounded-md border border-input bg-background",
          "px-3 py-2 text-base ring-offset-background",
          "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground",
          "file:bg-transparent file:text-sm file:font-medium file:text-foreground",
          "placeholder:text-muted-foreground focus-within:outline-none", 
          "focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2", 
          "disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
      >
        {leftIcon && leftIcon}
        <input
          type={type}
          className={cn(
            "h-full w-full",
            "focus-visible:outline-none",
            "bg-transparent"
          )}
          ref={ref}
          {...props}
        />
        {rightIcon && rightIcon}
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
