import { memo, useCallback } from "react";
import { toast } from "react-toastify";
import { useGetServiceAreasCompanyId, usePostServiceAreas } from "@services";
import {
  CompanyServiceAreasForm,
  CompanyServiceAreasFormValues,
} from "./components/CompanyServiceAreasForm";
import { SectionHeader } from "@components";
import { Skeleton } from "@/components/ui/skeleton";

export const ServiceAreasSkeleton = memo(() => (
  <div className="w-full space-y-4">
    <Skeleton className="h-10 w-full rounded-md" />
    <Skeleton className="h-32 w-full rounded-md" />
    <div className="flex justify-end">
      <Skeleton className="h-9 w-20 rounded-md" />
    </div>
  </div>
));

export const CompanyServiceAreas = memo(() => {
  const { data, isLoading } = useGetServiceAreasCompanyId();
  const { mutate: updateServiceAreas, isPending: isUpdateServiceAreasPending } =
    usePostServiceAreas();

  const onSubmit = useCallback(
    ({ serviceAreas: areas }: CompanyServiceAreasFormValues) => {
      const deleted = data.yes.filter(
        (value: string) => !areas.includes(value),
      );
      updateServiceAreas(
        { yes: areas, no: [], maybe: [], deleted: deleted },
        {
          onSuccess: () => {
            toast.success("Service areas updated successfully");
          },
          onError: () => {
            toast.error("Failed to update service areas");
          },
        },
      );
    },
    [updateServiceAreas, data],
  );

  return (
    <div className="flex flex-col h-full p-8">
      <SectionHeader title="Service Areas"></SectionHeader>
      {isLoading ? (
        <ServiceAreasSkeleton />
      ) : (
        <CompanyServiceAreasForm
          onSubmit={onSubmit}
          pending={isUpdateServiceAreasPending}
          serviceAreas={data?.yes ?? []}
        />
      )}
    </div>
  );
});

export default CompanyServiceAreas;
