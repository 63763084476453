import { FormItem } from "../ui/form";
import { forwardRef, memo } from "react";
import { FormControl, FormDescription, FormMessage } from "../ui/form";
import { FormLabel } from "../ui/form";
import { MultiSelect } from "../MultiSelect";
import { MultiSelectProps } from "../MultiSelect";
import { cn } from "@/utils/tailwind";

type FormMultiSelectProps = Omit<MultiSelectProps, "name" | "control"> & {
  label?: string;
  helperText?: string;
  className?: string;
  value?: string[];
};

export const FormMultiSelect = memo(forwardRef<HTMLButtonElement, FormMultiSelectProps>(
  ({
    options,
    placeholder,
    helperText,
    label,
    className,
    ...multiSelectProps
  }, ref) => {
    return (
      <FormItem className={cn("flex flex-col gap-1", className)}>
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <MultiSelect
            ref={ref}
            options={options}
            placeholder={placeholder}
            {...multiSelectProps}
          />
        </FormControl>
        <FormDescription>{helperText}</FormDescription>
        <FormMessage />
      </FormItem>
    );
  }),
);
