import { createPortal } from "react-dom";
import { useState, useRef, memo } from "react";
import { CallSummary } from "../CallSummary";

type CallSummaryTooltipProps = {
  children: React.ReactNode;
  summary: string;
};

export const CallSummaryTooltip = memo(
  ({ children, summary }: CallSummaryTooltipProps) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = (e: React.MouseEvent) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const tooltipWidth = tooltipRef.current?.offsetWidth || 410;
      const tooltipHeight = tooltipRef.current?.offsetHeight || 0;
      const offset = 12;

      setPosition({
        x: rect.left - tooltipWidth + offset,
        y: rect.top - tooltipHeight + offset,
      });
      setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
      setTooltipVisible(false);
    };

    return (
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        {createPortal(
          <div
            ref={tooltipRef}
            className={`fixed z-[9999] transition-opacity duration-200 pointer-events-none w-[410px]
            ${tooltipVisible ? "opacity-100" : "opacity-0"}`}
            style={{
              top: `${position.y}px`,
              left: `${position.x}px`,
            }}
          >
            <CallSummary summary={summary} />
          </div>,
          document.body,
        )}
      </div>
    );
  },
);
