import { Icon } from "@iconify/react/dist/iconify.js";
import { TransferPhone } from "@types";
import { FC, memo, useCallback } from "react";

export type PhoneItemProps = {
  onEdit: (phone: TransferPhone) => void;
  value: TransferPhone;
};

export const PhoneItem: FC<PhoneItemProps> = memo(({ onEdit, value }) => {
  const editHandler = useCallback(() => onEdit(value), [onEdit, value]);
  return (
    <div
      className={[
        "bg-background-50",
        "border-1",
        "border-solid",
        "border-foreground-700",
        "cursor-pointer",
        "flex",
        "flex-1",
        "justify-between",
        "p-4",
        "rounded-xl",
      ].join(" ")}
      onClick={editHandler}
      tabIndex={1}
    >
      <div className="flex gap-2 items-center">
        <Icon height={24} icon="fluent:call-forward-24-filled" />
        <span className="capitalize">{value.name}</span>
      </div>
      <span>{value.phoneNumber}</span>
    </div>
  );
});
