// tailwind.config.js
import tailwindCSSAnimate from "tailwindcss-animate";

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./index.html",
    "./src/**/*.{js,ts,jsx,tsx}",
  ],
  darkMode: ["selector", "class"],
  theme: {
    extend: {
      backgroundImage: {
        "dynamic-learning-gradient":
          "linear-gradient(145deg, #F1F8E9 27.57%, #CCFF90 106.91%)",
      },
      colors: {
        green: {
          A200: "#69F0AE",
        },
        default: {
          50: "var(--color-default-50)",
          100: "var(--color-default-100)",
          200: "var(--color-default-200)",
          300: "var(--color-default-300)",
          400: "var(--color-default-400)",
          500: "var(--color-default-500)",
          600: "var(--color-default-600)",
          700: "var(--color-default-700)",
          800: "var(--color-default-800)",
          900: "var(--color-default-900)",
          DEFAULT: "var(--color-default)",
          active: "#78716C",
          foreground: "var(--color-default-foreground)",
        },
        primary: {
          DEFAULT: "hsl(var(--primary))",
          active: "hsl(var(--primary-active))",
          foreground: "hsl(var(--primary-foreground))",
        },
        background: {
          50: "var(--color-background-50)",
          100: "var(--color-background-100)",
          200: "var(--color-background-200)",
          300: "var(--color-background-300)",
          400: "var(--color-background-400)",
          500: "var(--color-background-500)",
          600: "var(--color-background-600)",
          700: "var(--color-background-700)",
          800: "var(--color-background-800)",
          900: "var(--color-background-900)",
          DEFAULT: "hsl(var(--background))",
        },
        foreground: {
          50: "var(--color-foreground-50)",
          100: "var(--color-foreground-100)",
          200: "var(--color-foreground-200)",
          300: "var(--color-foreground-300)",
          400: "var(--color-foreground-400)",
          500: "var(--color-foreground-500)",
          600: "var(--color-foreground-600)",
          700: "var(--color-foreground-700)",
          800: "var(--color-foreground-800)",
          900: "var(--color-foreground-900)",
          DEFAULT: "hsl(var(--foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        secondary: {
          50: "var(--color-secondary-50)",
          100: "var(--color-secondary-100)",
          200: "var(--color-secondary-200)",
          300: "var(--color-secondary-300)",
          400: "var(--color-secondary-400)",
          500: "var(--color-secondary-500)",
          600: "var(--color-secondary-600)",
          700: "var(--color-secondary-700)",
          800: "var(--color-secondary-800)",
          900: "var(--color-secondary-900)",
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        "muted-background": {
          DEFAULT: "hsl(var(--muted-background))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        info: {
          DEFAULT: "hsl(var(--info))",
          active: "hsl(var(--info-active))",
          foreground: "hsl(var(--info-foreground))",
        },
        border: "hsla(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        chart: {
          "1": "hsl(var(--chart-1))",
          "2": "hsl(var(--chart-2))",
          "3": "hsl(var(--chart-3))",
          "4": "hsl(var(--chart-4))",
          "5": "hsl(var(--chart-5))",
        },
        status: {
          scheduled: {
            bg: "hsl(var(--status-scheduled-bg))",
            text: "hsl(var(--status-scheduled-text))",
            hover: "hsl(var(--status-scheduled-hover))",
          },
          emergency: {
            bg: "hsl(var(--status-emergency-bg))",
            text: "hsl(var(--status-emergency-text))",
            hover: "hsl(var(--status-emergency-hover))",
          },
          support: {
            bg: "hsl(var(--status-support-bg))",
            text: "hsl(var(--status-support-text))",
            hover: "hsl(var(--status-support-hover))",
          },
          callback: {
            bg: "hsl(var(--status-callback-bg))",
            text: "hsl(var(--status-callback-text))",
            hover: "hsl(var(--status-callback-hover))",
          },
          transferred: {
            bg: "hsl(var(--status-transferred-bg))",
            text: "hsl(var(--status-transferred-text))",
            hover: "hsl(var(--status-transferred-hover))",
          },
          unqualified: {
            bg: "hsl(var(--status-unqualified-bg))",
            text: "hsl(var(--status-unqualified-text))",
            hover: "hsl(var(--status-unqualified-hover))",
          },
          datashared: {
            bg: "hsl(var(--status-datashared-bg))",
            text: "hsl(var(--status-datashared-text))",
            hover: "hsl(var(--status-datashared-hover))",
          },
          callscheduled: {
            bg: "hsl(var(--status-callscheduled-bg))",
            text: "hsl(var(--status-callscheduled-text))",
            hover: "hsl(var(--status-callscheduled-hover))",
          },
          insurance: {
            bg: "hsl(var(--status-insurance-bg))",
            text: "hsl(var(--status-insurance-text))",
            hover: "hsl(var(--status-insurance-hover))",
          },
        },
        sidebar: {
          DEFAULT: "hsl(var(--sidebar-background))",
          foreground: "hsl(var(--sidebar-foreground))",
          "foreground-group-header":
            "hsl(var(--sidebar-group-header-foreground))",
          primary: "hsl(var(--sidebar-primary))",
          "primary-foreground": "hsl(var(--sidebar-primary-foreground))",
          accent: "hsl(var(--sidebar-accent))",
          "accent-foreground": "hsl(var(--sidebar-accent-foreground))",
          "accent-active": "hsl(var(--sidebar-accent-active))",
          "accent-active-foreground":
            "hsl(var(--sidebar-accent-active-foreground))",
          border: "hsl(var(--sidebar-border))",
          ring: "hsl(var(--sidebar-ring))",
        },
        topheader: {
          DEFAULT: "hsl(var(--top-header-background))",
          foreground: "hsl(var(--top-header-foreground-primary))",
        },
        success: {
          50: "var(--color-success-50)",
          100: "var(--color-success-100)",
          200: "var(--color-success-200)",
          300: "var(--color-success-300)",
          400: "var(--color-success-400)",
          500: "var(--color-success-500)",
          600: "var(--color-success-600)",
          700: "var(--color-success-700)",
          800: "var(--color-success-800)",
          900: "var(--color-success-900)",
          DEFAULT: "var(--color-success)",
          foreground: "var(--color-success-foreground)",
        },
        warning: {
          50: "var(--color-warning-50)",
          100: "var(--color-warning-100)",
          200: "var(--color-warning-200)",
          300: "var(--color-warning-300)",
          400: "var(--color-warning-400)",
          500: "var(--color-warning-500)",
          600: "var(--color-warning-600)",
          700: "var(--color-warning-700)",
          800: "var(--color-warning-800)",
          900: "var(--color-warning-900)",
          DEFAULT: "var(--color-warning)",
          foreground: "var(--color-warning-foreground)",
        },
        danger: {
          50: "var(--color-danger-50)",
          100: "var(--color-danger-100)",
          200: "var(--color-danger-200)",
          300: "var(--color-danger-300)",
          400: "var(--color-danger-400)",
          500: "var(--color-danger-500)",
          600: "var(--color-danger-600)",
          700: "var(--color-danger-700)",
          800: "var(--color-danger-800)",
          900: "var(--color-danger-900)",
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
        inherit: "inherit",
      },
      keyframes: {
        ripple: {
          "0%": { transform: "scale(0)", opacity: 0.5 },
          "100%": { transform: "scale(4)", opacity: 0 },
        },
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        ripple: "ripple 600ms linear infinite",
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    fontFamily: {
      menlo: ["Menlo", "monospace"],
      ibmMono: ["IBM Plex Mono", "monospace"],
      ppNeueMachinaBold: ["PP Neue Machina Bold"],
    },
  },
  plugins: [
    tailwindCSSAnimate,
  ],
};
