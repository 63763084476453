import { useSession } from "@store";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";

interface UpdateServiceAreasRequest {
  accessToken?: string;
  companyId?: string;
  deleted: string[];
  maybe: string[];
  no: string[];
  yes: string[];
}

const updateServiceAreas = async ({
  accessToken,
  companyId,
  yes,
  no,
  maybe,
  deleted,
}: UpdateServiceAreasRequest) => {
  const baseUrl =
    localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    if (deleted.length > 0) {
      await Promise.all(
        deleted.map((value) =>
          axios.delete(`${baseUrl}/service-areas/${value}/${companyId}`, {
            headers,
          }),
        ),
      );
    }

    if (yes.length > 0 || no.length > 0 || maybe.length > 0) {
      const response = await axios.post(
        `${baseUrl}/service-areas/`,
        { companyId, yes, no, maybe },
        { headers },
      );
      return response.data;
    }

    return null;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Failed to update service areas:",
        error.response?.data || error.message,
      );
    } else {
      console.error("Failed to update service areas:", error);
    }
    throw error;
  }
};

export const usePostServiceAreas = () => {
  const queryClient = useQueryClient();
  const { accessToken, companyId } = useSession();
  return useMutation({
    mutationFn: ({
      yes,
      no,
      maybe,
      deleted,
    }: {
      yes: string[];
      no: string[];
      maybe: string[];
      deleted: string[];
    }) =>
      updateServiceAreas({ accessToken, companyId, yes, no, maybe, deleted }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["useGetServiceAreasCompanyId", companyId],
      });
    },
  });
};
