import { useCompaniesById, usePatchCompany } from "@services";
import { BusinessHours, Slot, WeekDay } from "@types";
import { toast } from "react-toastify";
import { useBlocker } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useCallback, useEffect, useState } from "react";
import { TimeSlider } from "./components/TimeSlider";
import { Button, SectionHeader } from "@components";
import { Card, CardContent } from "src/common/components/ui/card";
import { Skeleton } from "src/common/components/ui/skeleton";

const defaultSlot: Slot = { start: "09:00", end: "17:00" };

const defaultBusinessHours: BusinessHours = {
  monday: { open: true, slots: [defaultSlot] },
  tuesday: { open: true, slots: [defaultSlot] },
  wednesday: { open: true, slots: [defaultSlot] },
  thursday: { open: true, slots: [defaultSlot] },
  friday: { open: true, slots: [defaultSlot] },
  saturday: { open: false, slots: [defaultSlot] },
  sunday: { open: false, slots: [defaultSlot] },
};

const fillEmptySlots = (businessHours: BusinessHours) => {
  return Object.keys(businessHours).reduce((acc, day) => {
    const dayHours = businessHours[day as WeekDay];
    if (dayHours) {
      const slots = dayHours.slots;
      acc[day as WeekDay] = {
        open: dayHours.open,
        slots: slots.length ? slots : [defaultSlot],
      };
    } else {
      acc[day as WeekDay] = {
        open: false,
        slots: [defaultSlot],
      };
    }
    return acc;
  }, {} as BusinessHours);
};

export const CompanyHours = memo(() => {
  const queryClient = useQueryClient();
  const company = useCompaniesById();
  const { mutate: patchCompany, isPending: isPatchCompanyPending } =
    usePatchCompany();

  const [businessHours, setBusinessHours] = useState<BusinessHours>(
    fillEmptySlots(defaultBusinessHours),
  );

  const applyToAllHandler = useCallback(
    (open: boolean, slots: Slot[]) => {
      const updatedSlots = Object.keys(businessHours).reduce((acc, day) => {
        acc[day as WeekDay] = { open, slots };
        return acc;
      }, {} as BusinessHours);
      setBusinessHours(updatedSlots);
    },
    [businessHours],
  );

  const changeHandler = useCallback(
    ({ day, open, slots }: { day: WeekDay; open: boolean; slots: Slot[] }) =>
      setBusinessHours({ ...businessHours, [day]: { open, slots } }),
    [businessHours],
  );

  const saveHandler = useCallback(() => {
    patchCompany(
      { businessHours: businessHours },
      {
        onSuccess: () => {
          toast.success("Business hours updated successfully");
          queryClient.invalidateQueries({ queryKey: ["useCompaniesById"] });
        },
        onError: () => {
          toast.error("Failed to update business hours");
        },
      },
    );
  }, [businessHours, patchCompany, queryClient]);

  useEffect(() => {
    if (company.data?.businessHours && !company.isLoading) {
      setBusinessHours(fillEmptySlots(company.data?.businessHours));
    }
  }, [company.data?.businessHours, company.isLoading]);

  useBlocker(() => {
    if (
      company.data?.businessHours &&
      JSON.stringify(businessHours) !==
        JSON.stringify(fillEmptySlots(company.data?.businessHours))
    ) {
      return window.confirm(
        "Are you sure you want to leave this page? You have unsaved changes.",
      );
    }
    return false;
  });

  return (
    <div className="flex h-full flex-col px-8 pt-8 pb-8 overflow-auto">
      <SectionHeader title="Business Hours">
        <Button
          size="sm"
          variant="primary"
          disabled={isPatchCompanyPending}
          onClick={saveHandler}
          type="button"
        >
          {isPatchCompanyPending ? "Saving Hours..." : "Save Business Hours"}
        </Button>
      </SectionHeader>
      <div>
        <Card>
          <CardContent className="flex gap-8 flex-col p-8">
            {company.isLoading && (
              <div className="w-full flex items-center justify-center">
                <Skeleton className="h-8 w-8 rounded-full" />
              </div>
            )}
            {businessHours?.monday?.slots &&
              Object.keys(businessHours).map((day: string) => (
                <TimeSlider
                  key={day}
                  day={day as WeekDay}
                  slots={businessHours[day as WeekDay].slots}
                  onApplyToAll={applyToAllHandler}
                  onChange={changeHandler}
                  open={businessHours[day as WeekDay].open}
                />
              ))}
          </CardContent>
        </Card>
      </div>
    </div>
  );
});

export default CompanyHours;
