import * as React from "react";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { forwardRef } from "react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface DateTimePickerProps {
  value: string;
  onChange: (date: string) => void;
  onBlur?: () => void;
}

export const DateTimePicker = forwardRef<
  HTMLButtonElement,
  DateTimePickerProps
>(({ value, onChange, onBlur }, ref) => {
  // Parse the ISO string directly
  const [selectedDate, setSelectedDate] = React.useState<Date>(() => {
    const date = new Date(value);
    // Subtract timezone offset to preserve the date
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  });

  // Parse time directly from the ISO string
  const [hours, setHours] = React.useState(() => {
    const [, timePart] = value.split("T");
    const [hour] = timePart.split(":").map(Number);
    const hour12 =
      hour === 0 ? "12" : hour > 12 ? (hour - 12).toString() : hour.toString();
    return hour12.padStart(2, "0");
  });

  const [minutes, setMinutes] = React.useState(() => {
    const [, timePart] = value.split("T");
    const [, min] = timePart.split(":").map(Number);
    return min.toString().padStart(2, "0");
  });

  const [meridiem, setMeridiem] = React.useState<"AM" | "PM">(() => {
    const [, timePart] = value.split("T");
    const [hour] = timePart.split(":").map(Number);
    return hour >= 12 ? "PM" : "AM";
  });

  const handleDateSelect = (selected: Date | undefined) => {
    if (selected) {
      let newHours = Number.parseInt(hours) % 12;
      if (meridiem === "PM") {
        newHours += 12;
      }

      // Create ISO string directly
      const isoString = `${selected.getFullYear()}-${String(selected.getMonth() + 1).padStart(2, "0")}-${String(selected.getDate()).padStart(2, "0")}T${String(newHours).padStart(2, "0")}:${minutes}:00`;

      onChange(isoString);
      setSelectedDate(selected);
    }
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newHours = Number.parseInt(e.target.value);
    if (isNaN(newHours)) newHours = 12;
    if (newHours < 1) newHours = 1;
    if (newHours > 12) newHours = 12;
    setHours(newHours.toString().padStart(2, "0"));
    updateDateTime(newHours.toString(), minutes, meridiem);
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newMinutes = Number.parseInt(e.target.value);
    if (isNaN(newMinutes)) newMinutes = 0;
    if (newMinutes < 0) newMinutes = 0;
    if (newMinutes > 59) newMinutes = 59;
    setMinutes(newMinutes.toString().padStart(2, "0"));
    updateDateTime(hours, newMinutes.toString(), meridiem);
  };

  const handleMeridiemChange = (newMeridiem: "AM" | "PM") => {
    setMeridiem(newMeridiem);
    updateDateTime(hours, minutes, newMeridiem);
  };

  const updateDateTime = (
    hours: string,
    minutes: string,
    meridiem: "AM" | "PM",
  ) => {
    let newHours = Number.parseInt(hours) % 12;
    if (meridiem === "PM") {
      newHours += 12;
    }

    // Create ISO string directly
    const isoString = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(2, "0")}T${String(newHours).padStart(2, "0")}:${minutes}:00`;

    onChange(isoString);
  };

  return (
    <Popover onOpenChange={(open) => !open && onBlur?.()}>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal rounded-md",
            !value && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? (
            // Subtract timezone offset for display
            `${format(new Date(value).getTime() - new Date().getTimezoneOffset() * 60000, "PPP")} ${hours}:${minutes} ${meridiem}`
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={selectedDate}
          onSelect={handleDateSelect}
          initialFocus
        />
        <div className="p-3 border-t border-border flex items-center">
          <div className="grid grid-cols-2 gap-2 items-center">
            <Input
              type="number"
              min={1}
              max={12}
              value={hours}
              onChange={handleHoursChange}
              className="w-full"
            />
            <Input
              type="number"
              min={0}
              max={59}
              value={minutes}
              onChange={handleMinutesChange}
              className="w-full"
            />
          </div>
          <Select value={meridiem} onValueChange={handleMeridiemChange}>
            <SelectTrigger className="w-[70px] ml-2">
              <SelectValue placeholder="AM/PM" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="AM">AM</SelectItem>
              <SelectItem value="PM">PM</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </PopoverContent>
    </Popover>
  );
});

DateTimePicker.displayName = "DateTimePicker";
